import React, { Suspense } from 'react'
import { Route, Routes, Navigate, BrowserRouter, Outlet } from 'react-router-dom'
import loadable from '@loadable/component'
import Loading from 'components/Common/Loading'

const Main = loadable(() => import(/* webpackChunkName: "Main" */ '../containers/Main'), {
  fallback: <Loading />
})

const Home = loadable(() => import(/* webpackChunkName: "User" */ '../containers/Home'), {
  fallback: <Loading />
})

const Certification = loadable(() => import(/* webpackChunkName: "User" */ '../containers/Certification'), {
  fallback: <Loading />
})

const Callback = loadable(() => import(/* webpackChunkName: "Callback" */ '../containers/Callback'), {
  fallback: <Loading />
})

// const Hunting = loadable(() => import(/* webpackChunkName: "Hunting" */ '../containers/Hunting'), {
//   fallback: <Loading />
// })

export default (history) => (
  <BrowserRouter>
    <Routes>
      <Route element={<Callback />} path='/callback' />
      <Route
        element={(
          <Suspense fallback={() => <></>}>
            <Main history={history} >
              <Outlet />
            </Main>
          </Suspense>
        )}
        path='/'>
        <Route
          element={(
            <Suspense fallback={() => <></>}>
              <Home />
            </Suspense>
          )} path='home/:taskStatus'>
        </Route>
        <Route
          element={(
            <Suspense fallback={() => <></>}>
              <Certification />
            </Suspense>
          )} path='certification/:id' />
        <Route element={<Navigate to='/home/assigned' />} path='/home' />
        <Route element={<Navigate to='/home' />} path='/' />
      </Route>
    </Routes>
  </BrowserRouter>
)
