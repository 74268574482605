import { call, put, takeEvery, select } from 'redux-saga/effects'
import { ErrorType, Post } from 'utils/Request'
import ducks from 'reducers/assigners/assign'
import userDucks from 'reducers/user'
import tasksDucks from 'reducers/tasks'
import taskConfigurationsDucks from 'reducers/taskConfigurations'

const { selectors: selectorsUser } = userDucks

const { types } = ducks

const { REACT_APP_RESTPONSIBLE } = process.env

function* assign({ args }) {
  try {
    yield put({ type: types.POST_PENDING })
    const Authorization = yield select(selectorsUser.getToken)

    const { data: activityKrowder, message, error, success } = yield call(Post, `${REACT_APP_RESTPONSIBLE}/assigners/assign`, args, { Authorization })

    const tasksList = yield select(taskConfigurationsDucks.selectors.getTasksList)

    yield put({
      payload: {
        data     : activityKrowder,
        error,
        notify   : { message },
        searching: false,
        success,
        tasksList
      },
      type: tasksDucks.types.TASK_ASSIGNED
    })

    yield put({
      payload: {
        activityKrowder
      },
      type: types.POST_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.POST, assign)
]
