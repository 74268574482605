import { call, put, takeEvery, select } from 'redux-saga/effects'
import { Get, Post, ErrorType } from 'utils/Request'
import profilesDucks from 'reducers/profiles'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks
// const TwilioCall = new CallService().use('twilio')

const { types } = profilesDucks

const {
  REACT_APP_RESTPONSIBLE
} = process.env

function* getProfilesJobMatch({ params }) {
  try {
    yield put({ type: types.LOADING_PROFILES_JOBMATCH })

    const Authorization = yield select(selectorsUser.getToken)

    const {  success, message, data }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/hunting/registersJobMatch`,
      params,
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type: types.SUCCESS_PROFILES_JOBMATCH
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* verifyFreeze({ activityKrowderId }) {
  try {
    yield put({ type: types.LOADING_VERIFY_FREEZE })

    const Authorization = yield select(selectorsUser.getToken)

    const {  success, message, data }  = yield call(
      Get,
      `${REACT_APP_RESTPONSIBLE}/hunting/verifyFreeze/${activityKrowderId}`,
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type: types.SUCCESS_VERIFY_FREEZE
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* associateProfiles({ params }) {
  try {
    yield put({ type: types.LOADING_ASSOCIATE_PROFILES })

    const Authorization = yield select(selectorsUser.getToken)

    const {  success, message, data }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/hunting/associateProfiles`,
      params,
      { Authorization }
    )

    yield put({
      data,
      message,
      selection: params.candidateIds,
      success,
      type     : types.SUCCESS_ASSOCIATE_PROFILES
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* associateCandidate({ params }) {
  try {
    yield put({ type: types.LOADING_ASSOCIATE_CANDIDATE })

    const Authorization = yield select(selectorsUser.getToken)

    const {  success, message, data }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/hunting/associateCandidate`,
      params,
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type: types.SUCCESS_ASSOCIATE_CANDIDATE
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.GET_PROFILES_JOB_MATCH, getProfilesJobMatch),
  takeEvery(types.VERIFY_FREEZE, verifyFreeze),
  takeEvery(types.ASSOCIATE_PROFILES, associateProfiles),
  takeEvery(types.ASSOCIATE_CANDIDATE, associateCandidate)
]
