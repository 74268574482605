import { call, put, takeEvery, select } from 'redux-saga/effects'
import { Get, ErrorType } from 'utils/Request'
import jobDucks from 'reducers/job'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks

const { types } = jobDucks

const {
  REACT_APP_ATS_RESTIFY
} = process.env

function* getJobInformation({ jobId }) {
  try {
    yield put({ type: types.LOADING_GET_JOB_INFO })

    const Authorization = yield select(selectorsUser.getToken)

    const {  success, message, data : { jobs } }  = yield call(
      Get,
      `${REACT_APP_ATS_RESTIFY}/job/jobinfo?jobId=${jobId}&publicationIndex=0`,
      { Authorization }
    )

    yield put({
      data: jobs,
      message,
      success,
      type: types.SUCCESS_GET_JOB_INFO
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.FETCH_INFO_JOB, getJobInformation)
]
