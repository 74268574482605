import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action'

import base from '../base'

export default base({
  initialState: {
    total: 0
  },
  namespace: 'krowdy',
  store    : 'assigners/getTotalPending'
}).extend({
  creators: ({ types }) => ({
    getTotalPending: () => ({
      [ERROR_ACTION]   : types.FETCH_FAILURE,
      [WAIT_FOR_ACTION]: types.FETCH_FULFILLED,
      type             : types.FETCH
    })
  }),
  selectors: ({ store }) => ({
    getTotalPending: state => state[store].total
  })
})
