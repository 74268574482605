import { call, put, takeEvery, select } from 'redux-saga/effects'

import { Get, Post, ErrorType } from 'utils/Request'

import invoicesDucks from 'reducers/invoices'
import taskDucks from 'reducers/tasks'
import walletDucks from 'reducers/wallet'
import userDucks from 'reducers/user'

const { types } = invoicesDucks
const { types: taskTypes } = taskDucks
const { types: callTypes } = walletDucks
const { selectors: selectorsUser } = userDucks

const { REACT_APP_RESTPONSIBLE } = process.env

function* getInvoices() {
  try {
    yield put({ type: types.FETCH_PENDING })
    const Authorization = yield select(selectorsUser.getToken)

    const {
      data: {
        invoices
      }
    } = yield call(
      Get,
      `${REACT_APP_RESTPONSIBLE}/invoices/all`,
      { Authorization }
    )

    yield put({
      payload: { invoices },
      type   : types.FETCH_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

function* createInvoice({ activityKrowderIds, storage, voucherName }) {
  try {
    yield put({ type: types.POST_PENDING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data: { invoice } }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/invoices/create`,
      {
        activityKrowderIds,
        storage,
        voucherName
      },
      { Authorization }
    )

    yield put({
      activityKrowderIds,
      invoiceId    : invoice._id,
      statusPayment: 'invoiced',
      type         : taskTypes.CHANGE_STATUS_FINISHED_TASKS
    })

    yield put({
      type: callTypes.RESET_UPLOAD_BILLING
    })

    yield put({
      invoice,
      type: types.SUCCESS_CREATE_INVOICE
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* cancelInvoice({ invoiceId }) {
  try {
    yield put({ type: types.POST_PENDING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data: { invoice } }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/invoices/cancel`,
      {
        invoiceId
      },
      { Authorization }
    )

    yield put({
      activityKrowderIds: invoice.activityKrowderIds,
      statusPayment     : 'pending',
      type              : taskTypes.CHANGE_STATUS_FINISHED_TASKS
    })

    yield put({
      invoice,
      type: types.SUCCESS_CANCEL_INVOICE
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* selectInvoice({ invoiceId }) {
  try {
    yield put({ invoiceId,  type: types.FETCH_INVOICE_PENDING })

    if(invoiceId) {
      const Authorization = yield select(selectorsUser.getToken)

      const { data: { tasks } }  = yield call(
        Get,
        `${REACT_APP_RESTPONSIBLE}/invoices/activities/${invoiceId}`,
        { Authorization }
      )

      yield put({
        tasks,
        type: taskTypes.FETCH_INVOICE_FULLFIELD
      })
    } else {
      yield put({
        type: taskTypes.FETCH_DATA_FINISHEDS
      })
    }
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.FETCH_INVOICES, getInvoices),
  takeEvery(types.CREATE_INVOICE, createInvoice),
  takeEvery(types.CANCEL_INVOICE, cancelInvoice),
  takeEvery(types.SELECT_INVOICE, selectInvoice)
]
