import { all } from 'redux-saga/effects'

import tasks from './tasks'
import user from './user'
import invoices from './invoices'
import hunting from './hunting'
import job from './job'
import profiles from './profiles'
import wallet from './wallet'
import taskConfigurations from './taskConfigurations'
import assign from './assigners/assign'
import getTotalPending from './assigners/getTotalPending'
import rejectAndResolveNextPending from './assigners/rejectAndResolveNextPending'
import resolveNextPending from './assigners/resolveNextPending'
import updateCertification from './assigners/updateCertification'
import voucherValid from './vouchers/valid'

export default function* rootSaga() {
  yield all([
    ...wallet,
    ...tasks,
    ...user,
    ...invoices,
    ...hunting,
    ...job,
    ...profiles,
    ...taskConfigurations,
    ...assign,
    ...getTotalPending,
    ...rejectAndResolveNextPending,
    ...resolveNextPending,
    ...updateCertification,
    ...voucherValid
  ])
}
