import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import DateFnsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider } from '@ghondar/pickers'

import App from './App'

if(module.hot) module.hot.accept()

const render = (Component, type = 'render') => {
  ReactDOM[type](
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AppContainer key={Math.random()}>
        <Component />
      </AppContainer>
    </MuiPickersUtilsProvider>,
    document.getElementById('root')
  )
}

render(App)

// Webpack Hot Module Replacement API
if(module.hot)
  module.hot.accept('./App', () => {
    render(require('./App').default)
  })
