import XDate from 'xdate'
export const getTaskStatus = (taskStatus, taskType) => {
  let status = ''

  if((taskStatus.includes('pending') || taskStatus.includes('doing'))) {
    if(taskType === 'video-interview')
      status = 'Ejecución / Revisión'
    else if(taskType === 'tracking')
      status = 'Proactivo'
  } else if(taskStatus.includes('executed')) {
    status = 'Revisión'
  }

  return status
}

export const queryString = (string) => {
  var pairs = string.slice(1).split('&')

  var result = {}
  pairs.forEach((pair) => {
    pair = pair.split('=')
    result[pair[0]] = decodeURIComponent(pair[1] || '')
  })

  return JSON.parse(JSON.stringify(result))
}

export function windowSize() {
  let size = [ 0, 0 ]
  if(typeof window.innerWidth !== 'undefined')
    size = [ window.innerWidth, window.innerHeight ]
  else
  if(typeof document.documentElement !== 'undefined' &&
      typeof document.documentElement.clientWidth !== 'undefined' &&
      document.documentElement.clientWidth !== 0)

    size = [
      document.documentElement.clientWidth,
      document.documentElement.clientHeight
    ]

  else
    size = [
      document.getElementsByTagName('body')[0].clientWidth,
      document.getElementsByTagName('body')[0].clientHeight
    ]

  return size
}
export const generateFileName = (file) => {
  let timestamp =  XDate()

  return timestamp + '-' + file.name.replace(/ /g, '_')
}

export function downloadURI(uri, name) {
  fetch(uri).then(function(t) {
    return t.blob().then((b) => {
      var a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', name)
      a.click()
    })
  })
}

export function msToTime(duration) {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor((duration / (1000 * 60 * 60 * 24)))

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds

  if(days >= 1)
    return `${days} día${days > 1 ? 's' : ''}`
  else
    return hours + ':' + minutes + ':' + seconds
}

export const openInNewTab = url => {
  const win = window.open(url, '_blank')
  win.focus()
}

export const calculateFinishedTime = (ms) => {
  const days = Math.floor((ms / (60 * 60 * 24))),
    hours = Math.floor((ms / (60 * 60)) % 24),
    minutes = Math.ceil((ms / 60) % 60)

  if(days)
    return {
      time: days,
      type: `día${days > 1 ? 's' : '' }`
    }
  else if(hours)
    return {
      time: hours,
      type: `hora${hours > 1 ? 's' : '' }`
    }
  else if(minutes)
    return {
      time: minutes,
      type: 'min'
    }

  return {}
}

export const keyBy = (arr, key) => arr.reduce((acc, el) => {
  acc[el[key]] = el

  return acc
}, {})

export const getFullNameByUser = (user) => {
  if(!user) return ''

  if(user.firstName && user.lastName)
    return `${user.firstName} ${user.lastName}`

  if(user.firstName && !user.lastName)
    return user.firstName

  if(!user.firstName && user.lastName)
    return user.lastName

  const [ firstMail ] = user.emails ?? []

  const email = user.email ?? firstMail?.value

  return email ?? ''
}

export const toUpperCaseFirstLetter = (str) => str[0].toUpperCase() + str.slice(1)
