import produce from 'immer'
import base from './base'

export default base({
  initialState: {
    uploadVoucher: {
      activeBucket: '',
      key         : '',
      path        : '',
      urlS3       : ''
    }
  },
  namespace: 'krowdy',
  store    : 'wallet'
}).extend({
  creators: ({ types }) => ({
    resetUploadBilling: (params) => ({ params, type: types.RESET_UPLOAD_BILLING }),
    uploadBilling     : (params) => ({ params, type: types.UPLOAD_BILLING })
  }),
  reducer: (state, action, { types, initialState }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.POST_UPLOAD_BILLING:
          draft.loadingUploadVoucher = true
          break

        case types.RESET_UPLOAD_BILLING:
          draft.loadingUploadVoucher = initialState.loadingUploadVoucher
          draft.successUploadVoucher = initialState.successUploadVoucher
          draft.uploadVoucher = initialState.uploadVoucher
          break

        case types.FETCH_FULFILLED_UPLOAD_BILLING:
          draft.successUploadVoucher = action.success
          draft.validateVoucherSunat = action.validateVoucherSunat
          draft.messageValidateVoucher = action.validateVoucherSunat ? 'El voucher es válido por SUNAT' : 'El voucher no es válido por SUNAT'

          draft.loadingUploadVoucher = false
          for (let key in action.payload) draft.uploadVoucher[key] = action.payload[key]

          break

        case types.FETCH_FAILURE:
        case types.FETCH_CANCEL:
          draft.loadingUploadVoucher = false

          break

        default:
          break
      }
    }),
  selectors: ({ store }) => ({
    getStorage             : state => state[store].uploadVoucher,
    getSuccessUploadVoucher: state => state[store].successUploadVoucher,
    getValidateVoucherSunat: state => state[store].validateVoucherSunat
  }),
  types: [
    'UPLOAD_BILLING',
    'POST_UPLOAD_BILLING',
    'FETCH_FULFILLED_UPLOAD_BILLING',
    'RESET_UPLOAD_BILLING'
  ]
})
