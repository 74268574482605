import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action'

import base from '../base'

export default base({
  initialState: {
    activityKrowder: null
  },
  namespace: 'krowdy',
  store    : 'assigners/assign'
}).extend({
  creators: ({ types }) => ({
    assign: ({ refId, type }) => ({
      [ERROR_ACTION]   : types.POST_FAILURE,
      [WAIT_FOR_ACTION]: types.POST_FULFILLED,
      args             : {
        refId,
        type
      },
      type: types.POST
    })
  })
})
