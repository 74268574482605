import { combineReducers } from 'redux'

import tasks from './tasks'
import schedule from './schedule'
import user from './user'
import krowders from './krowders'
import jobs from './jobs'
import company from './company'
import invoices from './invoices'
import hunting from './hunting'
import job from './job'
import profiles from './profiles'
import wallet from './wallet'
import taskConfigurations from './taskConfigurations'
import assign from './assigners/assign'
import getTotalPending from './assigners/getTotalPending'
import rejectAndResolveNextPending from './assigners/rejectAndResolveNextPending'
import resolveNextPending from './assigners/resolveNextPending'
import updateCertification from './assigners/updateCertification'
import voucherValid from './vouchers/valid'

export default () =>
  combineReducers({
    jobs,
    krowders,
    schedule,
    [invoices.store]                   : invoices.reducer,
    [tasks.store]                      : tasks.reducer,
    [user.store]                       : user.reducer,
    [company.store]                    : company.reducer,
    [hunting.store]                    : hunting.reducer,
    [job.store]                        : job.reducer,
    [profiles.store]                   : profiles.reducer,
    [wallet.store]                     : wallet.reducer,
    [taskConfigurations.store]         : taskConfigurations.reducer,
    [assign.store]                     : assign.reducer,
    [getTotalPending.store]            : getTotalPending.reducer,
    [rejectAndResolveNextPending.store]: rejectAndResolveNextPending.reducer,
    [resolveNextPending.store]         : resolveNextPending.reducer,
    [updateCertification.store]        : updateCertification.reducer,
    [voucherValid.store]               : voucherValid.reducer
  })

