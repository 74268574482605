// import like from 'sounds/state-change_confirm-up.wav'
import { TitleName, dateES } from 'utils/constantes'
import XDate from 'xdate'

XDate.locales['es'] = dateES

export function filterTaskReject(tasks, userId) {
  const taskFilter = tasks.filter(task => !task.rejected.some(function(krowders) {
    return krowders.krowderId === userId
  }))

  return taskFilter
}

export function existsFirtsTaskCurrent(taskCurrent, task) {
  let existtask = false

  existtask = taskCurrent.some(function(current) {
    return current.activityKrowderId === task.activityKrowderId
  })

  return existtask
}

// se usa
const verificationTimeViewTask = (taskCurrent, tasksList = {}) => {
  let validdate = false

  const { verificationTime, timeViewTask, timeExeption, verificationExeption } = tasksList[taskCurrent.codeTask]
  const { status } = taskCurrent
  if(verificationTime && !status.includes('executed'))
  {
    const diff = (Date.parse(new Date(taskCurrent.taskDate)) - Date.parse(new Date())) / 1000
    if(verificationExeption)
    {
      if(diff <= timeExeption)
        validdate = false
      else if(diff <= timeViewTask)
        validdate = true
    }
    else
    {
      const { end, start, enabled } = taskCurrent.calls.activityTracking.blockedHours

      if(enabled)
      {let startDate = new XDate().setHours(start).setMinutes(0).setSeconds(0).getTime()

        let enddate = new XDate().setHours(end).setMinutes(0).setSeconds(0).getTime()

        const dateNow = new XDate().getTime()

        // if(((startDate <= dateNow) && (dateNow < enddate)) && enabled) // para probar
        if(((dateNow <= startDate)  || (dateNow >= enddate)))
          validdate = true
        else
          validdate = false
      } else {
        validdate = true
      }
    }
  } else {
    validdate = true
  }

  return validdate
}
// const likeAudio = new Audio(like)
// const playSound = audioFile => {
//   audioFile.play()
// }

/**
 * @deprecated
 * @param {state.taskCurrent} tasksCurrent
 * @param {draft.taskCurrent} draftasksCurrent
 * @param {newTasks} tasks
 * @param {UserAction} userId
 * @param {draft.tasks} draftTasks
 */
// @deprecated si en algun momento se piensa utilizar nuevamente esta funcion. Actualizar su ejecucion y mandar tasksList
export function AddTasksAndUpdate(tasksCurrent, draftasksCurrent, tasks, userId, draftTasks, filter, tasksList) {
  const filterReject = filterTaskReject(tasks, userId)

  const filterTypeApp = filterReject.filter(t => filter.find(f => f.nameApp === t.codeTask && f.status))

  filterTypeApp.forEach(c => {
    const { nameTask } = tasksList[c.codeTask]

    c.bloqueo = TaskBlocked(c)

    if(!existsFirtsTaskCurrent(tasksCurrent, c))
    {
      draftasksCurrent.push(c)

      if(verificationTimeViewTask(c, tasksList))

        if(nameTask === TitleName.VIDEO_INTERVIEW)
          draftTasks.unshift(c)
        else
          draftTasks.push(c)

      // playSound(likeAudio)
    } else {
      let { activityKrowderId } = c

      draftasksCurrent.splice(draftasksCurrent.findIndex((t) => t.activityKrowderId === activityKrowderId), 1)
      draftasksCurrent.push(c)

      if(draftTasks.findIndex((t) => t.activityKrowderId === activityKrowderId) !== -1)
      {
        draftTasks.splice(draftTasks.findIndex((t) => t.activityKrowderId === activityKrowderId), 1)
        if(verificationTimeViewTask(c, tasksList))
          if(nameTask === TitleName.VIDEO_INTERVIEW)
            draftTasks.unshift(c)
          else
            draftTasks.push(c)
      } else {
        if(verificationTimeViewTask(c, tasksList))
          if(nameTask === TitleName.VIDEO_INTERVIEW)
            draftTasks.unshift(c)
          else
            draftTasks.push(c)
      }
    }
  })
}

// se usa
export function TaskBlocked(task, tasksList) {
  let bloqueo = {
    bloktask   : false,
    hourBlocked: new XDate()
  }

  if(task.codeTask === TitleName.TRACKING)
  {
    const { end } = task.calls.activityTracking.blockedHours
    let enddate = new XDate().setHours(end).setMinutes(0).setSeconds(0).getTime()
    bloqueo = {
      bloktask   : task.calls.finished ? false : !verificationTimeViewTask(task, tasksList),
      hourBlocked: enddate
    }
  }

  return bloqueo
}

// @deprecated si en algun momento se piensa utilizar nuevamente esta funcion. Actualizar su ejecucion y mandar tasksList
export function BlockedTasks(taskAssignedCurrent, draftasksAssignedCurrent, tasks, tasksList) {
  if(tasks)
    for (const task of tasks)
    {
      let newTask = task
      newTask.bloqueo = TaskBlocked(task, tasksList)

      if(draftasksAssignedCurrent.findIndex((t) => t.activityKrowderId === newTask.activityKrowderId) !== -1)
        draftasksAssignedCurrent.splice(draftasksAssignedCurrent.findIndex((t) => t.activityKrowderId === newTask.activityKrowderId), 1)

      draftasksAssignedCurrent.push(newTask)
    }
}

export function updateDataToHunting(dratfData, newHuntingData) {
  const indexFound = dratfData.findIndex((t) => t._id === newHuntingData.candidateHuntingId)

  if(indexFound !== -1) {
    let huntingObjectDraft = dratfData[indexFound]
    const { state = huntingObjectDraft.state } = newHuntingData
    huntingObjectDraft.state = state
  }
}

export function updateDataPosConfirmation(dratfData,  { candidateId }) {
  const indexFound = dratfData.findIndex((t) => t.candidateId === candidateId)

  if(indexFound !== -1)
    dratfData[indexFound]['status'] = 'invited'
}

export function updateRegistersBeforeDelete(draftData, candidateHuntingId) {
  const indexFound = draftData.findIndex(({ _id }) => _id === candidateHuntingId)
  if(indexFound !== -1)
    draftData.splice(indexFound, 1)
}

// eslint-disable-next-line no-unused-vars
export function updateListCandidates(draftPagination = {}, draftRegister, actionData, draftReferred) {
  if(draftReferred)
    draftReferred.referredId =  actionData.candidate

  /*  if(draftRegister.length > 9) {
    draftCount = actionData.countregister

    return
  } */
  draftPagination.totalRegisters = draftPagination.totalRegisters + 1

  const { candidate, firstName, lastName, linkedin, emails, referentName, referentLastName, phone, fullName, ...others } = actionData

  draftRegister.push({
    candidateId: candidate,
    createdAt  : Date.now(),
    emails     : [ emails ],
    firstName,
    fullName   : fullName ? fullName : `${firstName} ${lastName}`,
    isReferred : Boolean(draftReferred),
    linkedin   : linkedin,
    missingData: !linkedin && !emails && !phone,
    phone,
    referent   : {
      firstName: referentName,
      lastName : referentLastName
    },
    ...others
  })
}

export const updateCountRegisters = (draftData, typeCount) => {
  draftData[typeCount] = draftData[typeCount] + 1
}

export const updateReferredEdit = (draftData, { candidate }) => {
  const indexFound = draftData.findIndex((t) => t.candidateId === candidate)

  if(indexFound !== -1)
    draftData[indexFound].missingData = false
}

const parseDetailJob = (objectDetail = {}) => {
  if(!Object.keys(objectDetail).length) return []
  const arrayRes = []
  for (const elem in objectDetail)
    if(elem === 'salary') {
      const { from = 0, to = 0, typeOfMoney = '' } = objectDetail[elem] || {}
      if(from && to)
        arrayRes.push({ title: 'salary', value: `${typeOfMoney} ${from} - ${to}` })
    } else if(objectDetail[elem] &&  objectDetail[elem] !== '') {
      arrayRes.push({ title: elem, value: objectDetail[elem] })
    }

  return arrayRes
}

export const parseJobData = ({ Company, Publication } = {}) => {
  const {
    salary,
    journeyType,
    hierarchy,
    location,
    contractType,
    area,
    experience,
    educationLevel,
    careers = [],
    availabilityToTravel,
    disability,
    profile:{
      languages = [],
      ...othersFieldsProfile
    } = {},
    ...othersFields
  } = Publication
  const detailJob = parseDetailJob({  area, contractType, hierarchy, journeyType, location, salary  })

  const requirements = []
  if(experience)
    requirements.push({ title: 'Tiempo de experiencia laboral', value: `${experience} Año${experience > 1 ? 's' : ''}` })

  if(educationLevel && (educationLevel.condition && educationLevel.level))
    requirements.push({ title: 'Nivel de educación', value: `${educationLevel.level} - ${educationLevel.condition}` })

  if(careers.length > 0)
    requirements.push({ title: 'Carreras', value: careers })

  if(languages.length)
    requirements.push({ title: 'Idioma', value: languages.map(({ language = '', level = '' }) => `${language} - ${level}`) })

  if(availabilityToTravel)
    requirements.push({ title: 'Disponibilidad para viajar', value: availabilityToTravel ? 'Sí' : 'No' })

  if(disability && disability.visible)
    requirements.push({ title: 'Apto para personas con discapacidad', value: disability.accepted ? 'Sí' : 'No' })

  return {
    ...othersFields,
    ...othersFieldsProfile,
    company: Company,
    detailJob,
    requirements
  }
}

export const parseProfilesJobMatch = (newData = []) => newData.map((fields) => {
  const { emails = [], experience, salaryExpectation, fullName, ...others } = fields
  const [ lastExperience ] = experience.slice(-1)

  return (
    {
      area   : lastExperience.area,
      company: lastExperience.companyName,
      email  : emails[0],
      experience,
      fullName,
      salary : salaryExpectation.amount,
      ...others
    }
  )
})

export const updateChangeCandidates = (draftDataRegisters, { candidateId, candidateIdChange }) => draftDataRegisters.map((register) => {
  if(register.candidateId === candidateId)
    return draftDataRegisters.find(({ candidateId : id }) => id === candidateIdChange)

  if(register.candidateId  === candidateIdChange)
    return draftDataRegisters.find(({ candidateId : id }) => id === candidateId)

  return register
})
