import qs from 'querystring'
import { call, put, takeEvery, select } from 'redux-saga/effects'
import { ErrorType, Get } from 'utils/Request'
import ducks from 'reducers/assigners/getTotalPending'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks

const { types } = ducks

const { REACT_APP_RESTPONSIBLE } = process.env

function* getTotalPending() {
  try {
    yield put({ type: types.FETCH_PENDING })
    const Authorization = yield select(selectorsUser.getToken)
    const codeTasks = yield select(selectorsUser.getFilters)

    const { data: total } = yield call(Get,
      `${REACT_APP_RESTPONSIBLE}/assigners/total/pending?${qs.stringify({ codeTasks })}`,
      { Authorization },
      { onlyRoute: true }
    )

    yield put({
      payload: {
        total
      },
      type: types.FETCH_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.FETCH, getTotalPending)
]
