import produce from 'immer'
import { WAIT_FOR_ACTION } from 'redux-wait-for-action'

import base from './base'

export default base({
  initialState: {
    name : 'Krowdy',
    theme: {
      palette: {
        primary: {
          main: '#1890FF'
        },
        secondary: {
          main: '#002766'
        }
      }
    }
  },
  namespace: 'krowdy',
  store    : 'company'
}).extend({
  creators: ({ types }) => ({
    getTheme: () => ({ [WAIT_FOR_ACTION]: types.FETCH_FULFILLED_THEME, type: types.FETCH_THEME })
  }),
  reducer: (state, action, { types }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.FETCH_FULFILLED_THEME:
          for (let key in action.payload) draft[key] = action.payload[key]
          draft.status = 'READY_THEME'

          return
        default:
          return
      }
    }),
  types: [ 'FETCH_THEME', 'FETCH_FULFILLED_THEME' ]
})
