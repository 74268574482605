import base from './base'

export default base({
  initialState: {
    itemByCodeTask: {},
    items         : []
  },
  namespace: 'krowdy',
  store    : 'taskConfigurations'
}).extend({
  creators: ({ types }) => ({
    fetchTaskConfigurations: () => ({ type: types.FETCH })
  }),
  selectors: ({ store }) => ({
    getTasksList: state => state[store].itemByCodeTask
  })
})
