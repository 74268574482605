import produce from 'immer'
import base from './base'
import { keyLocalStorage } from 'utils/constantes'

export default base({
  initialState: {
    audioDevices  : { input: [], output: [] },
    cookies       : {},
    findDevices   : false,
    showModalAudio: false,
    success       : false,
    userData      : {
      filter: []
    },
    userId: null
  },
  namespace: 'krowdy',
  store    : 'user'
}).extend({
  creators: ({ types }) => ({
    getAuth             : () => ({ type: types.FETCH }),
    logout              : () => ({ type: types.POST_LOGOUT }),
    setAudioDevices     : (audioDevices) => ({ audioDevices, type: types.FETCH_AUDIO_DEVICES }),
    setCookies          : cookies => ({ cookies, type: types.REHYDRATE_AUTH }),
    toggleModalAudio    : (showModalAudio) => ({ showModalAudio, type: types.FETCH_MODAL_STATUS }),
    updateFilterTypeTask: (filter) => ({ filter, type: types.UPDATE_TYPE_FILTER_TASKS }),
    verifyUser          : () => ({ type: types.FETCH_DATA_USER })

  }),
  reducer: (state, action, {
    types
  }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.REHYDRATE_AUTH:
          draft.cookies = action.cookies
          break
        case types.POST_LOGOUT:
          break
        case types.UPDATE_TYPE_FILTER_TASKS:

          draft.userData.filter.forEach(element => {
            if(element.nameApp === action.filter.name)
              element.status = action.filter.value
          })

          const shortFilters = draft.userData.filter.map(({ nameApp, status }) => ({ nameApp, status }))
          localStorage.setItem(keyLocalStorage.Filter, JSON.stringify(shortFilters))

          draft.status = 'UPDATE_FILTER_TYPE_TASKS'

          break
        case types.FETCH_AUDIO_DEVICES:
          draft.audioDevices = action.audioDevices
          draft.findDevices = true
          break
        case types.FETCH_MODAL_STATUS:
          draft.showModalAudio = action.showModalAudio
          break
        default:
          return
      }
    }),
  selectors: ({ store }) => ({
    getCookies      : state => state[store].cookies,
    getFilterKrowder: state => state[store].userData.filter,
    getFilters      : state => {
      const { userData: { filter } } = state[store]

      const filterTasks = []

      filter.forEach((task) => {
        if(task.status)
          filterTasks.push(task.nameApp)
      })

      return filterTasks
    },
    getIsKrowder: state => state[store].userData.krowder,
    getToken    : state => `Bearer ${state[store].cookies.accessToken}`,
    getUserId   : state => state[store].userId
  }),
  types: [
    'REHYDRATE_AUTH',
    'FETCH_DATA_USER',
    'POST_LOGOUT',
    'UPDATE_TYPE_FILTER_TASKS',
    'FETCH_AUDIO_DEVICES',
    'FETCH_MODAL_STATUS'
  ]
})
