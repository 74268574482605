import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action'

import base from '../base'
import produce from 'immer'

export default base({
  initialState: {
    pendingTask: null
  },
  namespace: 'krowdy',
  store    : 'assigners/resolveNextPending'
}).extend({
  creators: ({ types }) => ({
    resolveNextPending: () => ({
      [ERROR_ACTION]   : types.POST_FAILURE,
      [WAIT_FOR_ACTION]: types.POST_FULFILLED,
      type             : types.POST
    })
  }),
  reducer: (state, action, { types }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.UPDATE_CERTIFICATION:
          draft.pendingTask.certified = action.payload.certified
          break
        default:
          break
      }
    }),
  selectors: ({ store }) => ({
    nextPending: state => state[store].pendingTask
  }),
  types: [ 'UPDATE_CERTIFICATION' ]
})
