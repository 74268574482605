import { call, put, takeEvery, select } from 'redux-saga/effects'
import { Get, GetList, Post, ErrorType } from 'utils/Request'

import taskDucks from 'reducers/tasks'
import userDucks from 'reducers/user'
import taskConfigurationsDucks from 'reducers/taskConfigurations'

const { types, selectors: selectorsTask } = taskDucks
const { selectors: selectorsUser } = userDucks
const { selectors: selectorsTaskConfigurations } = taskConfigurationsDucks

const { REACT_APP_RESTPONSIBLE, REACT_APP_ATS_RESTIFY } = process.env

function* getPendings({ filter, searching }) {
  try {
    yield put({ searching, type: types.FETCH_LOADING_DATA_PENDING })
    const Authorization = yield select(selectorsUser.getToken)
    const userId = yield select(selectorsUser.getUserId)

    const filterTasks = yield select(selectorsUser.getFilters)

    const {
      data: {
        tasks,
        total
      }
    } = yield call(
      Get,
      `${REACT_APP_RESTPONSIBLE}/activities/pool?filter=${filterTasks.join(',')}`,
      { Authorization }
    )

    yield put({
      filter,
      searching,
      tasks,
      total,
      type: types.FETCH_DATA_PENDING,
      userId
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

function* getAssigned() {
  try {
    yield put({ type: types.FETCH_LOADING_DATA_ASSIGNED })
    const Authorization = yield select(selectorsUser.getToken)
    const assignedFilters = yield select(selectorsTask.getAssignedFilters)

    const {
      data: {
        tasks
      }
    } = yield call(
      GetList,
      `${REACT_APP_RESTPONSIBLE}/activities/assigned`,
      { Authorization, query: assignedFilters }
    )

    tasks.sort((a, b) => {
      if(a.codeTask === 'video-interview') {
        if(a.status.includes('executed'))
          a.taskDate = null
        if(b.status.includes('executed'))
          b.taskDate = null

        return new Date(a.taskDate) - new Date(b.taskDate) | (new Date(a.taskDate) >= new Date(b.taskDate))
      }

      return false
    })

    yield put({
      tasks,
      type: types.FETCH_DATA_ASSIGNED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

function* getFinished() {
  try {
    yield put({ type: types.FETCH_LOADING_DATA_FINISHED })
    const Authorization = yield select(selectorsUser.getToken)
    const assignedFilters = yield select(selectorsTask.getAssignedFilters)
    const paginationFinishTasks = yield select(selectorsTask.getPaginationFinishedTasks)

    const {
      data : {
        tasks,
        pagination
      } = {},
      success
    } = yield call(
      GetList,
      `${REACT_APP_RESTPONSIBLE}/activities/finished`,
      { Authorization, query: { ...assignedFilters, ...paginationFinishTasks } }
    )

    tasks.sort((a, b) => {
      if(a.codeTask === 'video-interview') {
        if(a.status.includes('executed'))
          a.taskDate = null
        if(b.status.includes('executed'))
          b.taskDate = null

        return new Date(a.taskDate) - new Date(b.taskDate) | (new Date(a.taskDate) >= new Date(b.taskDate))
      }

      return false
    })

    yield put({
      pagination,
      success,
      tasks,
      type: types.FETCH_DATA_FINISHED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

function* getCertification({ resultTaskId }) {
  try {
    yield put({ type: types.POST_PENDING })

    // const filter = yield select(selectors.getFilters)
    const Authorization = yield select(selectorsUser.getToken)

    const { data }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tasks/certification`,
      {
        resultTaskId
      },
      { Authorization }
    )

    yield put({
      payload: {
        certification: data
      },
      type: types.POST_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getHistory({ dates }) {
  try {
    yield put({ type: types.SEND_FETCH_DATA_LOGS })

    // const filter = yield select(selectors.getFilters)
    const Authorization = yield select(selectorsUser.getToken)

    const { data:{ tasks } }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tasks/history`,
      {
        ...dates
      },
      { Authorization }
    )

    yield put({

      logs: tasks,
      skip: dates.skip,
      type: types.SUCCESS_DATA_LOGS
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getCountHistory({ dates }) {
  try {
    yield put({ type: types.POST_PENDING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data:{ rows } }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tasks/historyCount`,
      {
        ...dates
      },
      { Authorization }
    )

    yield put({
      payload: {
        countLogs: rows
      },
      type: types.POST_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getBalance({ taskDates }) {
  try {
    yield put({ type: types.SEND_FETCH_BALANCE })

    // const filter = yield select(selectors.getFilters)
    const Authorization = yield select(selectorsUser.getToken)

    const { data : { result, summatory } }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/wallet/balance`,
      {
        ...taskDates,
        timezoneDiff: new Date().getTimezoneOffset()
      },
      { Authorization }
    )

    yield put({
      balance: result,
      summatory,
      type   : types.SUCCESS_FETCH_BALANCE
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* assignedTasksKrowder({ task }) {
  try {
    yield put({ type: types.POST_PENDING })

    const Authorization = yield select(selectorsUser.getToken)
    const tasksList = yield select(selectorsTaskConfigurations.getTasksList)

    const { _id: activityKrowderId } = task
    const data = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/activities/assign`,
      {
        activityKrowderId
      },
      { Authorization }
    )

    const { message, success, error, data:activityKrowder } = data

    yield put({
      payload: {
        data     : activityKrowder,
        error,
        notify   : { message },
        searching: false,
        success,
        tasksList
      },
      type: types.TASK_ASSIGNED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* updateCertification({ activityKrowderId }) {
  try {
    yield put({ type: types.SEND_UPDATE_CERTIFICATION })

    const Authorization = yield select(selectorsUser.getToken)

    const  { success }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/activities/certificate`,
      {
        activityKrowderId
      },
      { Authorization }
    )

    yield put({
      payload: {
        success
      },
      type: types.UPDATE_CERTIFICATION
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    yield put({
      type: types.FAILED_CONFIRM_REQUEST
    })
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* rejectTasks({ activityKrowderId }) {
  try {
    yield put({ type: types.REJECT_TASK_PENDING })

    const Authorization = yield select(selectorsUser.getToken)

    const filter = yield select(selectorsUser.getFilters)

    const { data, success } = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/activities/ommit`,
      {
        activityKrowderId,
        filter
      },
      { Authorization }
    )

    yield put({
      payload: {
        activityKrowderId,
        data,
        success
      },
      type: types.REJECT_TASK_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* removeTask({ activityKrowderId }) {
  try {
    yield put({
      type: types.POST_REMOVE_TASK_PENDING
    })

    const Authorization = yield select(selectorsUser.getToken)

    const { success, message } = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/activitykrowders/deleteActivityKrowdersById`,
      { activityKrowderId },
      { Authorization }
    )
    yield put({
      activityKrowderId,
      message,
      success,
      type: types.POST_REMOVE_TASK_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* cancelTask({ activityKrowderId }) {
  try {
    yield put({
      type: types.POST_CANCEL_TASK_PENDING
    })

    const Authorization = yield select(selectorsUser.getToken)

    const { success, message } = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/activities/cancel`,
      { activityKrowderId },
      { Authorization }
    )
    yield put({
      activityKrowderId,
      message,
      success,
      type: types.POST_CANCEL_TASK_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* finishTask({ activityKrowderId }) {
  try {
    yield put({
      type: types.POST_FINISH_TASK_PENDING
    })

    const Authorization = yield select(selectorsUser.getToken)

    const { success, message } = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/activities/finishTask`,
      { activityKrowderId },
      { Authorization }
    )
    yield put({
      activityKrowderId,
      message,
      success,
      type: types.POST_FINISH_TASK_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* verifyException({  activityKrowderId }) {
  try {
    yield put({ type: types.POST_VERIFY_EXCEPTION })

    const Authorization = yield select(selectorsUser.getToken)

    const data = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tasks/verify/exception`,
      {
        activityKrowderId
      },
      { Authorization }
    )

    yield put({
      payload: {
        activityKrowderId,
        data
      },
      type: types.EXCEPTION_VERIFIED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

/* function* refreshAvailableTask({ filter }) {
  try {
    yield put({ type: types.POST_PENDING })
    const Authorization = yield select(selectorsUser.getToken)
    const {
      data: {
        tasks: data
      }
    } = yield call(
      Get,
      `${REACT_APP_RESTPONSIBLE}/activities/pool`,
      { Authorization }
    )

    yield put({
      data,
      filter,
      type: types.UPDATE_AVAILABLE_TASKS
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}
 */
function* verifyReposeForTask({  activityKrowderId }) {
  try {
    yield put({ type: types.POST_PENDING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data } = yield call(Post, `${REACT_APP_RESTPONSIBLE}/call/repose/update`,
      {
        activityKrowderId
      },
      { Authorization }
    )

    yield put({
      activityKrowderId,
      data,
      type: types.POST_UPDATE_DATA_FOR_ACTIVITY
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

function* updateStatusTask({  activityKrowderId }) {
  try {
    yield put({ type: types.POST_PENDING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data } = yield call(Post, `${REACT_APP_RESTPONSIBLE}/activities/updateStatus`,
      {
        activityKrowderId,
        status: 'doing'
      },
      { Authorization }
    )

    yield put({
      activityKrowderId,
      data,
      type: types.POST_UPDATE_STATUS_TASK
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE })
        break
    }
  }
}

function* getVideoUrlCertification({ taskId }) {
  try {
    yield put({ type: types.LOADING_VIDEO_URL_CERTIFICATION })

    const Authorization = yield select(selectorsUser.getToken)

    const {  success, message, data  }  = yield call(
      Post,
      `${REACT_APP_ATS_RESTIFY}/api/v1/tasks/certification`,
      { taskId },
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type: types.SUCCESS_VIDEO_URL_CERTIFICATION
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.FETCH_DATA_PENDINGS, getPendings),
  takeEvery(types.FETCH_DATA_ASSIGNEDS, getAssigned),
  takeEvery(types.FETCH_DATA_FINISHEDS, getFinished),
  takeEvery(types.FETCH_DATA_CERTIFICATION, getCertification),
  takeEvery(types.FETCH_DATA_LOGS, getHistory),
  takeEvery(types.FETCH_DATA_COUNTS_LOGS, getCountHistory),
  takeEvery(types.FETCH_DATA_BALANCE, getBalance),
  takeEvery(types.UPDATE_DATA_CERTIFICATION, updateCertification),
  takeEvery(types.POST_ASSIGNED, assignedTasksKrowder),
  takeEvery(types.UPDATE_STATUS_TASK, updateStatusTask),
  takeEvery(types.REJECT_TASK, rejectTasks),
  takeEvery(types.CANCEL_TASK, cancelTask),
  takeEvery(types.REMOVE_TASK, removeTask),
  takeEvery(types.VERIFY_EXCEPTION, verifyException),
  takeEvery(types.VERIFY_REPOSE_FOR_ACTIVITY, verifyReposeForTask),
  takeEvery(types.FETCH_URL_VIDEO_CERTIFICATION, getVideoUrlCertification),
  takeEvery(types.POST_FINISH_TASK, finishTask)
]
