import produce from 'immer'
import base from './base'
import { updateDataToHunting, updateListCandidates, updateDataPosConfirmation, updateCountRegisters, updateReferredEdit, updateChangeCandidates } from './const'

export default base({
  initialState: {
    allPostulants         : [],
    allReferents          : [],
    certificationConfirmed: false,
    countRegisters        : {
      invited  : 0,
      postulant: 0,
      referent : 0
    },
    dataRegisters    : [],
    dataToHunting    : {},
    error            : false,
    freezeHuntingDate: null,
    hunting          : {
      candidatesQuantity: 0,
      isCertified       : false,
      jobId             : '',
      typeHunting       : {
        candidate: {
          countRegister: 0
        }
      },
      types: []
    },
    idsSelectedFromChange   : {},
    loading                 : false,
    loadingCandidateReferent: false,
    loadingChangeCandidates : false,
    loadingConfirmCandidate : false,
    loadingCountRegisters   : false,
    loadingGetData          : false,
    loadingGetReferents     : false,
    loadingGetRegister      : false,
    loadingPostulants       : false,
    newHunting              : {
      candidateHunting: {}
    },
    newReferred: {
      referent: {}
    },
    notify: {
      message: '',
      type   : 'info'
    },
    pagination: {
      page          : 1,
      totalPages    : 1,
      totalRegisters: 0
    },
    success               : false,
    successAddCandidate   : false,
    successChangeCandidate: false
  },
  namespace: 'krowdy',
  store    : 'hunting'
}).extend({
  creators: ({ types }) => ({
    addCandidateReferred       : (params, referent) => ({ params, referent, type: types.FETCH_CANDIDATE_REFERRED }),
    addHunting                 : (params, typeHunting) => ({ params, type: types.FETCH_DATA_ADD_HUNTING, typeHunting }),
    changeCandidateIdSelected  : (params) => ({ params, type: types.FETCH_CHANGE_ID_SELECTED }),
    closeNotification          : () => ({ type: types.CLOSE_NOTIFY }),
    getAlertMessage            : (dates) => ({ dates, type: types.FETCH_MESSAGE_ALERT_HUNTING }),
    getAllPostulants           : (params) => ({ params, type: types.GET_ALL_POSTULANTS }),
    getAllReferents            : (params) => ({ params, type: types.FETCH_ALL_REFERENTS }),
    getCountRegisters          : (params) => ({ params, type: types.FETCH_COUNT_REGISTERS }),
    getDataToHunting           : (dataToHunting) => ({ dataToHunting, type: types.FETCH_DATA_TO_HUNTING }),
    getRegistersHunting        : (dates) => ({ dates, type: types.FETCH_GET_REGISTERS_HUNTING }),
    getdataHunting             : (resultTaskId) => ({ resultTaskId, type: types.FETCH_GET_DATA_HUNTING }),
    resetNewHunting            : (isReferred) => ({ isReferred, type: types.RESET_FIELDS_NEW_HUNTING }),
    setCandidateHuntingRowTable: (params) => ({ params, type: types.SET_CANDIDATE_HUNTING }),
    setChangeCandidates        : (params) => ({ params, type: types.CANDIDATES_CHANGE_RANKING }),
    setNewReferent             : (referent) => ({ referent, type: types.FETCH_REFERENT_DATA }),
    setPagination              : (field, number) => ({ field, number, type: types.SET_PAGINATION }),
    updateConfirmHunting       : (params, typeHunting) => ({ params, type: types.UPDATE_CANDIDATE_HUNTING, typeHunting }),
    updateFreezeDate           : (date) => ({ date, type: types.UPDATE_FREEZE_DATE }),
    updateReferred             : (params) => ({ params, type: types.UPDATE_REFERRED })
  }),
  reducer: (state, action, { types }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.RESET_FIELDS_NEW_HUNTING:
          if(action.isReferred)
            draft.newReferred.referredId = ''
          else
            draft.newHunting = {}
          break
        case types.SEND_FETCH_LOADING_ADD_HUNTING:
          draft.newHunting = {}
          draft.notify.message = 'Generando...'
          draft.loading = true
          break
        case types.SET_PAGINATION:
          draft.pagination[action.field] = action.number
          break
        case types.SUCCESS_DATA_HUNTING:
          if(action.typeHunting === 'referent')
            updateCountRegisters(draft.countRegisters, 'referent')
          if(action.success && action.typeHunting === 'candidate')
            updateListCandidates(draft.pagination, draft.dataRegisters, action.data)

          draft.newHunting = action.data
          draft.success = action.success
          draft.error = !action.success
          draft.notify = {
            message: action.message,
            type   : !action.success ? 'error' : 'success'
          }
          draft.loading = false
          break
        case types.FAILURE_DATA_HUNTING:
          draft.success = action.success
          draft.error = !action.success
          draft.notify = {
            message: action.message,
            type   : !action.success ? 'error' : 'success'
          }
          draft.loading = false
          break
        case types.SET_CANDIDATE_HUNTING :
          draft.newHunting.candidateHunting = action.params
          break
        case types.FETCH_DATA_TO_HUNTING:
          draft.dataToHunting = action.dataToHunting
          break

        case types.SEND_FETCH_LOADING_GET_HUNTING:
          draft.loadingGetData = true
          break

        case types.SUCCESS_GET_DATA_HUNTING:
          const { totalRegisters, ...huntingData } = action.data
          draft.pagination.totalRegisters = totalRegisters
          draft.hunting = huntingData
          draft.loadingGetData = false
          break

        case  types.FETCH_MESSAGE_ALERT_HUNTING:

          const { error, message } = action.dates
          draft.error = error === undefined ? false : error
          draft.success = !error
          draft.notify.type = error ? 'error' : 'success'
          draft.notify.message = message
          break

        case types.CLOSE_NOTIFY:
          draft.loading = false
          draft.error = false
          draft.success = false
          draft.notify.message = ''

          break
        case types.SEND_FETCH_LOADING_GET_REGISTER_HUNTING:
          draft.loadingGetRegister = true
          break
        case types.FETCH_LOADING_POSTULANTS:
          draft.loadingPostulants = true
          draft.allPostulants  = []
          break
        case types.SUCCESS_ALL_POSTULANTS:
          draft.allPostulants = action.data
          draft.loadingPostulants = false
          draft.successPostulants = action.success
          break
        case types.SUCCESS_GET_DATA_REGISTERS_HUNTING:
          draft.dataRegisters = action.data
          draft.loadingGetRegister = false
          break
        case types.LOADING_CANDIDATE_REFERRED :
          draft.notify.message = 'Guardando...'
          draft.loading = true
          break
        case types.SUCCESS_UPDATE_HUNTING:
          updateDataToHunting(draft.dataRegisters, action.dataUpdate)
          draft.loading = false
          draft.success = action.success
          draft.error = !action.success
          draft.notify = {
            message: action.message,
            type   : !action.success ? 'error' : 'success'
          }
          break
        case types.LOADING_TO_REFERENTS:
          draft.loadingGetReferents = true
          break
        case types.SUCCES_REFERENTS_DATA:
          if(action.success)
            draft.allReferents = action.data

          draft.loadingGetReferents = false
          break
        case types.SUCCESS_SEND_CANDIDATE_REFERRED:
          if(action.success)
            updateListCandidates(draft.pagination, draft.dataRegisters, action.data, draft.newReferred)

          draft.success = action.success
          draft.error = !action.success
          draft.loading = false
          draft.notify = {
            message: action.message,
            type   : !action.success ? 'error' : 'success'
          }
          break
        case types.FETCH_CHANGE_ID_SELECTED:
          Object.keys(action.params).forEach((key) => {
            draft.idsSelectedFromChange[key] = action.params[key]
          })
          break
        case types.LOADING_SEND_CHANGE_CANDIDATES :
          draft.loadingChangeCandidates = true
          break
        case types.SUCCESS_SET_CHANGE_CANDIDATES :
          if(action.success)
            draft.dataRegisters = updateChangeCandidates(draft.dataRegisters, action.data)

          draft.successChangeCandidate = action.success
          draft.success = action.success
          draft.notify = {
            message: action.message,
            type   : !action.success ? 'error' : 'success'
          }
          draft.loadingChangeCandidates = false
          break
        case types.LOADING_CONFIRM_CANDIDATE :
          draft.loadingConfirmCandidate = true
          break
        case types.SUCCESS_CONFIRM_HUNTING :
          draft.newHunting.candidate = null
          if(action.success) {
            updateDataPosConfirmation(draft.dataRegisters, action.data)
            updateCountRegisters(draft.countRegisters, 'candidates')
          }
          draft.success = action.success
          draft.notify = {
            message: action.message,
            type   : !action.success ? 'error' : 'success'
          }
          draft.loading = false
          draft.loadingConfirmCandidate = false
          break
        case types.FETCH_REFERENT_DATA :
          draft.newReferred.referent = action.referent
          break
        case types.LOADING_COUNT_REGISTERS:
          draft.loadingCountRegisters = true
          break
        case types.SUCCESS_COUNT_REGISTERS:
          draft.countRegisters = action.data
          draft.loadingCountRegisters = false
          break
        case types.LOADING_UPDATE_REFERRED:
          draft.loading = true
          draft.notify.message = 'Guardando...'
          break
        case types.SUCCESS_UPDATE_REFERRED:
          if(action.success)
            updateReferredEdit(draft.dataRegisters, action.data)
          draft.error = !action.success
          draft.success = action.success
          draft.notify = {
            message: action.message,
            type   : !action.success ? 'error' : 'success'
          }
          draft.loading = false
          break
        case types.UPDATE_FREEZE_DATE:
          draft.hunting.freezeHuntingDate = action.date
          break
        default:
          break
      }
    }),
  selectors: ({ store }) => ({
    getHuntingCount: state => ({
      loadingCountRegisters: state[store].loadingCountRegisters,
      ...state[store].countRegisters
    }),
    getHuntingData: state => ({
      loadingGetData: state[store].loadingGetData,
      ...state[store].hunting
    }),
    getNewHunting: state => ({
      loadingConfirmCandidate: state[store].loadingConfirmCandidate,
      ...state[store].newHunting
    }),
    getNotifyHunting    : state => state[store].notify,
    getPaginationHunting: state => state[store].pagination,
    getRegistersData    : state => ({
      dataRegisters     : state[store].dataRegisters,
      loadingGetRegister: state[store].loadingGetRegister
    }),
    getStatusProcess: state => {
      const { loading, error, success } = state[store]

      return { error, loading, success }
    }
  }),
  types: [
    'SEND_FETCH_LOADING_ADD_HUNTING',
    'SEND_FETCH_LOADING_GET_HUNTING',
    'SEND_FETCH_LOADING_GET_REGISTER_HUNTING',
    'SUCCESS_DATA_HUNTING',
    'SUCCESS_GET_DATA_HUNTING',
    'SUCCESS_GET_DATA_REGISTERS_HUNTING',
    'CLOSE_NOTIFY',
    'FETCH_UPDATE_CANDIDATE_HUNTING',
    'FETCH_DATA_ADD_HUNTING',
    'FETCH_MESSAGE_ALERT_HUNTING',
    'FETCH_DATA_TO_HUNTING',
    'FETCH_GET_DATA_HUNTING',
    'FETCH_GET_REGISTERS_HUNTING',
    'FETCH_CANDIDATE_REFERRED',
    'FETCH_ALL_REFERENTS',
    'LOADING_TO_REFERENTS',
    'SUCCES_REFERENTS_DATA',
    'FETCH_CANDIDATE_REFERENT',
    'LOADING_CANDIDATE_REFERRED',
    'SUCCESS_SEND_CANDIDATE_REFERRED',
    'SET_PAGINATION',
    'FETCH_CHANGE_ID_SELECTED',
    'CANDIDATES_CHANGE_RANKING',
    'LOADING_SEND_CHANGE_CANDIDATES',
    'SUCCESS_SET_CHANGE_CANDIDATES',
    'GET_ALL_POSTULANTS',
    'FETCH_LOADING_POSTULANTS',
    'SUCCESS_ALL_POSTULANTS',
    'UPDATE_CANDIDATE_HUNTING',
    'LOADING_CONFIRM_CANDIDATE',
    'SUCCESS_UPDATE_HUNTING',
    'SUCCESS_CONFIRM_HUNTING',
    'FETCH_REFERENT_DATA',
    'FETCH_COUNT_REGISTERS',
    'LOADING_COUNT_REGISTERS',
    'SUCCESS_COUNT_REGISTERS',
    'SET_CANDIDATE_HUNTING',
    'UPDATE_REFERRED',
    'LOADING_UPDATE_REFERRED',
    'SUCCESS_UPDATE_REFERRED',
    'RESET_FIELDS_NEW_HUNTING',
    'UPDATE_REFERRED',
    'UPDATE_FREEZE_DATE',
    'FAILURE_DATA_HUNTING'
  ]
})
