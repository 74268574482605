import produce from 'immer'
import base from './base'
import { parseProfilesJobMatch } from './const'
import { notify } from '@krowdy/kds-core'

export default base({
  initialState: {
    associateMessage  : '',
    currentProfileView: {},
    infoProfiles      : {
    },
    loadingAssociate         : false,
    loadingAssociateCandidate: false,
    loadingProfiles          : false,
    loadingVerifyFreeze      : false,
    messagefreeze            : '',
    newfreezeHuntingDate     : '',
    notify                   : {
      message: '',
      type   : 'info'
    },
    profilesJobMatch   : [],
    profilesSelect     : [],
    rowsProfiles       : [],
    success            : false,
    successAssociate   : false,
    successVerifyFreeze: false
  },
  namespace: 'krowdy',
  store    : 'profiles'
}).extend({
  creators: ({ types }) => ({
    associateCandidate  : (params) => ({ params, type: types.ASSOCIATE_CANDIDATE }),
    associateProfiles   : (params) => ({ params, type: types.ASSOCIATE_PROFILES }),
    getProfilesJobMatch : (params) => ({ params, type: types.GET_PROFILES_JOB_MATCH }),
    resetMessages       : (typeMessage) => ({ type: types.RESET_MESSAGES, typeMessage }),
    setCurrentProfile   : (profile) => ({ profile, type: types.SET_CURRENT_PROFILE }),
    setProfilesSelection: (selection) => ({ selection, type: types.SET_PROFILES_SELECT }),
    verificationFreeze  : (activityKrowderId) => ({ activityKrowderId, type: types.VERIFY_FREEZE })
  }),
  reducer: (state, action, { types }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.LOADING_PROFILES_JOBMATCH:
          draft.loadingProfiles = true
          break
        case types.SUCCESS_PROFILES_JOBMATCH:
          if(action.success) {
            draft.profilesJobMatch = parseProfilesJobMatch(action.data.docs)
            draft.infoProfiles = action.data.info
          }
          draft.loadingProfiles = false
          break
        case types.LOADING_VERIFY_FREEZE:
          draft.successVerifyFreeze = false
          draft.loadingVerifyFreeze = true
          break
        case types.SUCCESS_VERIFY_FREEZE:
          const { freezeHuntingDateExists, freezeHuntingDate } = action.data
          draft.successVerifyFreeze = action.success
          draft.newfreezeHuntingDate = freezeHuntingDate || freezeHuntingDateExists
          notify[action.success ? 'success' : 'error'](action.message)
          draft.messagefreeze = action.message
          draft.loadingVerifyFreeze = false
          break
        case types.LOADING_ASSOCIATE_PROFILES:
          draft.loadingAssociate = true
          break
        case types.SUCCESS_ASSOCIATE_PROFILES:
          draft.associateMessage = action.message
          notify[action.success ? 'success' : 'error'](action.message)
          draft.successAssociate = action.success
          if(action.success)
            draft.infoProfiles.totalDocs = draft.infoProfiles.totalDocs - action.selection.length
          draft.profilesSelect = []
          draft.loadingAssociate = false
          break
          /*  case types.SET_PROFILES_SELECT:
          draft.profilesSelect = state.profilesJobMatch.filter((el, index) => action.selection.includes(index)).map(({ _id }) => _id)
          break */
        case types.RESET_MESSAGES :
          if(action.typeMessage === 'associate')  {
            draft.associateMessage = ''
            draft.successAssociate = false
          } else if(action.typeMessage === 'freeze') {
            draft.successVerifyFreeze = false
            draft.messagefreeze = ''
          }
          break
        case types.LOADING_ASSOCIATE_CANDIDATE:
          draft.loadingAssociateCandidate = true
          break
        case types.SUCCESS_ASSOCIATE_CANDIDATE:
          notify[action.success ? 'success' : 'error'](action.message)
          draft.loadingAssociateCandidate = false
          break
        case types.SET_CURRENT_PROFILE:
          draft.currentProfileView = action.profile
          break
        default:
          break
      }
    }),
  selectors: ({ store }) => ({
    getFreezeData: state => {
      const {
        loadingVerifyFreeze,
        successVerifyFreeze,
        messagefreeze,
        newfreezeHuntingDate,
        infoProfiles
      } = state[store]

      return {
        infoProfiles,
        loadingVerifyFreeze,
        messagefreeze,
        newfreezeHuntingDate,
        successVerifyFreeze
      }
    }
  }),
  types: [
    'GET_PROFILES_JOB_MATCH',
    'LOADING_PROFILES_JOBMATCH',
    'SUCCESS_PROFILES_JOBMATCH',
    'VERIFY_FREEZE',
    'LOADING_VERIFY_FREEZE',
    'SUCCESS_VERIFY_FREEZE',
    'LOADING_ASSOCIATE_PROFILES',
    'SUCCESS_ASSOCIATE_PROFILES',
    'SET_PROFILES_SELECT',
    'RESET_MESSAGES',
    'ASSOCIATE_PROFILES',
    'ASSOCIATE_CANDIDATE',
    'LOADING_ASSOCIATE_CANDIDATE',
    'SUCCESS_ASSOCIATE_CANDIDATE',
    'SET_CURRENT_PROFILE'
  ]
})
