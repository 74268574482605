import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action'

import base from '../base'

export default base({
  initialState: {},
  namespace   : 'krowdy',
  store       : 'assigners/updateCertification'
}).extend({
  creators: ({ types }) => ({
    updateCertification: ({ taskId, activityKrowderId }) => ({
      [ERROR_ACTION]   : types.POST_FAILURE,
      [WAIT_FOR_ACTION]: types.POST_FULFILLED,
      activityKrowderId,
      taskId,
      type             : types.POST
    })
  })
})
