import { call, put, takeEvery, select } from 'redux-saga/effects'
import { Post, Put, ErrorType } from 'utils/Request'
import walletDucks from 'reducers/wallet'
import userDucks from 'reducers/user'
import voucherValidDucks from 'reducers/vouchers/valid'
import { generateFileName } from 'utils'

const { selectors: selectorsUser } = userDucks

const { types } = walletDucks

const {
  REACT_APP_RESTPONSIBLE
} = process.env

function* uploadBilling({ params }) {
  try {
    yield put({ type: types.POST_UPLOAD_BILLING })

    const Authorization = yield select(selectorsUser.getToken)

    const { voucher, route } = params
    const generateFile = generateFileName(voucher)

    const { data: { token, bucket } } = yield call(Post, `${REACT_APP_RESTPONSIBLE}/storage/token`, {
      Acl        : 'public-read',
      ContentType: voucher.type,
      Key        : `${route}/${generateFile}`
    },
    { Authorization })

    const urlS3 = token.substr(0, token.indexOf('?'))

    yield call(Put, token, voucher, { 'Content-Type': voucher.type, 'x-amz-acl': 'public-read' })

    yield put({
      activityKrowderIds: params.activityKrowderIds,
      type              : voucherValidDucks.types.POST,
      urlS3
    })

    yield put({
      payload: {
        activeBucket: bucket,
        key         : generateFile,
        path        : route,
        urlS3
      },
      type: types.FETCH_FULFILLED_UPLOAD_BILLING
    })
  } catch (error) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = error
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.UPLOAD_BILLING, uploadBilling)
]
