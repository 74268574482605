import { call, put, takeEvery, select } from 'redux-saga/effects'
import { Get, Post, ErrorType } from 'utils/Request'
import huntingDucks from 'reducers/hunting'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks
// const TwilioCall = new CallService().use('twilio')

const { types } = huntingDucks

const {
  REACT_APP_RESTPONSIBLE
} = process.env

function* addHunting({ params, typeHunting }) {
  try {
    yield put({ type: types.SEND_FETCH_LOADING_ADD_HUNTING })

    // const filter = yield select(selectors.getFilters)
    const Authorization = yield select(selectorsUser.getToken)
    let endpoint
    if(typeHunting === 'candidate')
      endpoint = 'candidate/create'
    else if(typeHunting === 'referent')
      endpoint = 'referent/create'

    const { data, success, message } = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/${endpoint}`, {
        [typeHunting]: params
      },
      { Authorization }
    )

    yield put({
      data: {
        ...data,
        ...params
      },
      message,
      success,
      type: types.SUCCESS_DATA_HUNTING,
      typeHunting
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    yield put({
      message: messageResponse,
      success: false,
      type   : types.FAILURE_DATA_HUNTING
    })
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getAllPostulants({ params }) {
  try {
    yield put({ type: types.FETCH_LOADING_POSTULANTS })

    const Authorization = yield select(selectorsUser.getToken)

    const { data, success, message } = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/candidate/registers`, params,
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type       : types.SUCCESS_ALL_POSTULANTS,
      typeHunting: params.type
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getdataHunting({ resultTaskId }) {
  try {
    yield put({ type: types.SEND_FETCH_LOADING_GET_HUNTING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data  }  = yield call(
      Get,
      `${REACT_APP_RESTPONSIBLE}/tracking/${resultTaskId}`,
      { Authorization }
    )

    yield put({
      data,
      type: types.SUCCESS_GET_DATA_HUNTING
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getRegistersHunting({ dates }) {
  try {
    yield put({ type: types.SEND_FETCH_LOADING_GET_REGISTER_HUNTING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data, success, error, message  }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/candidate/registers`,
      {
        ...dates
      },
      { Authorization }
    )

    yield put({
      data,
      error,
      message,
      success,
      type: types.SUCCESS_GET_DATA_REGISTERS_HUNTING
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* updateConfirmHunting({ params,  typeHunting }) {
  try {
    yield put({ type: types.LOADING_CONFIRM_CANDIDATE })

    const Authorization = yield select(selectorsUser.getToken)

    const { success, message }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/candidate/updateStatus`,
      params,
      { Authorization }
    )

    yield put({
      data: params,
      message,
      success,
      type: types.SUCCESS_CONFIRM_HUNTING,
      typeHunting
    })
  } catch (error) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = error
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getAllReferents({ params }) {
  try {
    yield put({ type: types.LOADING_TO_REFERENTS })

    const Authorization = yield select(selectorsUser.getToken)

    const { data, success, message }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/referent/registers`,
      params,
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type: types.SUCCES_REFERENTS_DATA
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}
function* addCandidateReferred({ params, referent }) {
  try {
    yield put({ type: types.LOADING_CANDIDATE_REFERRED })

    const Authorization = yield select(selectorsUser.getToken)

    const { success, message, data }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/candidate/create`,
      { candidate: params },
      { Authorization }
    )

    yield put({
      data: {
        ...params,
        ...data,
        ...referent
      },
      message,
      success,
      type: types.SUCCESS_SEND_CANDIDATE_REFERRED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* setChangeCandidates({ params }) {
  try {
    yield put({ type: types.LOADING_SEND_CHANGE_CANDIDATES })

    const Authorization = yield select(selectorsUser.getToken)

    const { success, message }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/candidate/changeRanking`,
      params,
      { Authorization }
    )

    yield put({
      data: params,
      message,
      success,
      type: types.SUCCESS_SET_CHANGE_CANDIDATES
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* getCountRegisters({ params }) {
  try {
    yield put({ type: types.LOADING_COUNT_REGISTERS })

    const Authorization = yield select(selectorsUser.getToken)

    const { data, success, message }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/tracking/candidate/countRegisters`,
      params,
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type: types.SUCCESS_COUNT_REGISTERS
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

function* updateReferred({ params }) {
  try {
    yield put({ type: types.LOADING_ASSOCIATE_CANDIDATE })

    const Authorization = yield select(selectorsUser.getToken)

    const {  success, message, data }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/hunting/addCandidateHunting`,
      params,
      { Authorization }
    )

    yield put({
      data,
      message,
      success,
      type: types.SUCCESS_ASSOCIATE_CANDIDATE
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.FETCH_DATA_ADD_HUNTING, addHunting),
  takeEvery(types.FETCH_GET_DATA_HUNTING, getdataHunting),
  takeEvery(types.FETCH_GET_REGISTERS_HUNTING, getRegistersHunting),
  takeEvery(types.UPDATE_CANDIDATE_HUNTING, updateConfirmHunting),
  takeEvery(types.FETCH_ALL_REFERENTS, getAllReferents),
  takeEvery(types.FETCH_CANDIDATE_REFERRED, addCandidateReferred),
  takeEvery(types.CANDIDATES_CHANGE_RANKING, setChangeCandidates),
  takeEvery(types.GET_ALL_POSTULANTS, getAllPostulants),
  takeEvery(types.FETCH_COUNT_REGISTERS, getCountRegisters),
  takeEvery(types.UPDATE_REFERRED, updateReferred)
]
