import store from 'src/store/configureStore'

const getStoreTasksList = () => {
  const state = store.getState()

  return state['taskConfigurations'].itemByCodeTask
}

export {
  getStoreTasksList
}
