const schedule = (state = { completed: false, scheduleLoading: false, scheduled: 0 }, action) => {
  switch (action.type) {
    case 'SCHEDULE_INTERVIEW':
      return { ...state, scheduleLoading: true }
    case 'SCHEDULE_VOD':
      return { ...state, scheduleLoading: true }
    case 'CLOSE_NO_SCHEDULED':
      return { ...state, completed: false, scheduled: 0 }
    case 'DATES_SCHEDULED':
      return {
        ...state,
        completed      : true,
        failed         : action.failed,
        scheduleLoading: false,
        scheduled      : action.scheduled,
        success        : action.success
      }
    case 'CLEAR_DATES_SCHEDULED':
      return { ...state, completed: false, failed: 0, scheduled: 0, success: false }
    default:
      return state
  }
}
export default schedule

