import axios from 'axios'

const { REACT_APP_REST_API_LOCATION = 'http://localhost:5000', REACT_APP_API_VERSION = 'v1' } = process.env

export const baseURL = REACT_APP_REST_API_LOCATION + '/api/' + REACT_APP_API_VERSION + '/'

export const ErrorType = {
  Cancel: 'cancel',
  Err   : 'err'
}

function serialize(obj) {
  var str = []
  for (var p in obj) if(obj.hasOwnProperty(p)) str.push(encodeURIComponent(p) + '=' + JSON.stringify(obj[p]))

  return str.join('&')
}

let _source, beforeRoute

export const http = function() {
  _source = axios.CancelToken.source()

  let instance = axios.create({
    baseURL    : baseURL,
    cancelToken: _source.token,
    mode       : 'no-cors'
  })

  return instance
}

export function Put(route, json = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    verifyRequestCancel(route)
    http()
      .put(route, json, { headers })
      .then(res => resolve(res.data))
      .catch(e => {
        reject({ type: axios.isCancel(e) ? ErrorType.Cancel : ErrorType.Err, ...e })
      })
  })
}

export function Delete(route, json = {}) {
  return new Promise((resolve, reject) => {
    verifyRequestCancel(route)
    http()
      .delete(route, { data: json })
      .then(res => resolve(res.data))
      .catch(e => {
        reject({ type: axios.isCancel(e) ? ErrorType.Cancel : ErrorType.Err, ...e })
      })
  })
}

export function Patch(route, json = {}) {
  return new Promise((resolve, reject) => {
    verifyRequestCancel(route)
    http()
      .patch(route, json)
      .then(res => resolve(res.data))
      .catch(e => {
        reject({ type: axios.isCancel(e) ? ErrorType.Cancel : ErrorType.Err, ...e })
      })
  })
}

export function Post(route, json = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    verifyRequestCancel(route)
    http()
      .post(route, json, { headers })
      .then(res => resolve(res.data))
      .catch(e => {
        reject({ type: axios.isCancel(e) ? ErrorType.Cancel : ErrorType.Err, ...e })
      })
  })
}

export function Get(_route, headers, options = { onlyRoute: false }) {
  return new Promise((resolve, reject) => {
    const route = options.onlyRoute ? _route.split('?')[0] : _route
    verifyRequestCancel(route)
    http()
      .get(_route, {
        headers
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        reject({ type: axios.isCancel(e) ? ErrorType.Cancel : ErrorType.Err, ...e })
      })
  })
}

export function GetList(product, { query, ...rest }) {
  return Get(product + '?' + serialize(query), rest)
}

function verifyRequestCancel(route) {
  if(beforeRoute === route) {
    if(_source !== undefined) _source.cancel('Operation canceled due to new request.')
  } else {
    beforeRoute = route
  }
}
