const krowders = (state = {}, action) => {
  switch (action.type) {
    case 'KROWDER_DATA_RECEIVED':
      const {   list  } = action

      return { ...state, list }
    default:
      return state
  }
}

export default krowders

