import { call, put, takeEvery, select } from 'redux-saga/effects'
import { ErrorType, Post } from 'utils/Request'
import ducks from 'reducers/assigners/updateCertification'
import resolveNextPendingDucks from 'reducers/assigners/resolveNextPending'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks

const { types } = ducks

const { REACT_APP_RESTPONSIBLE } = process.env

function* updateCertification({ taskId, activityKrowderId }) {
  try {
    yield put({ type: types.POST_PENDING })
    const Authorization = yield select(selectorsUser.getToken)

    const  { success }  = yield call(
      Post,
      `${REACT_APP_RESTPONSIBLE}/activities/certificate`,
      {
        activityKrowderId,
        taskId
      },
      { Authorization }
    )

    yield put({
      payload: {
        certified: success
      },
      type: resolveNextPendingDucks.types.UPDATE_CERTIFICATION
    })

    yield put({
      type: types.POST_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.POST, updateCertification)
]
