
import styled from 'styled-components'

export const LoadingContainer = styled.div({
  alignItems    : 'center',
  bottom        : 0,
  color         : '#BFBFBF',
  display       : 'flex',
  flexFlow      : 'column',
  height        : '30%',
  justifyContent: 'center',
  left          : 0,
  margin        : 'auto',
  position      : 'absolute',
  right         : 0,
  top           : 0,
  width         : '50%'
})
