import Duck from 'extensible-duck'
import produce from 'immer'
import base from './base'
// import { parseJobData } from './const'

export default base({
  initialState: {
    invoiceIdSelected: null,
    invoices         : []
  },
  namespace: 'krowdy',
  store    : 'invoices'
}).extend({
  creators: ({ types }) => ({
    cancelInvoice: (invoiceId) => ({ invoiceId, type: types.CANCEL_INVOICE }),
    createInvoice: (activityKrowderIds, storage, voucherName) => ({ activityKrowderIds, storage, type: types.CREATE_INVOICE, voucherName }),
    getInvoices  : () => ({ type: types.FETCH_INVOICES }),
    selectInvoice: (invoiceId) => ({ invoiceId, type: types.SELECT_INVOICE })
  }),
  reducer: (state, action, { types, statuses }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.SUCCESS_CREATE_INVOICE:

          draft.invoices.unshift({ ...action.invoice, type: 'invoice' })
          draft.status = statuses.SAVED
          break

        case types.SUCCESS_CANCEL_INVOICE:

          for (let invoice of draft.invoices)
            if(invoice._id === action.invoice._id) {
              invoice.status = action.invoice.status
              break
            }

          break

        case types.FETCH_INVOICE_PENDING:

          draft.invoiceIdSelected = action.invoiceId

          break
        default:
          break
      }
    }),
  selectors: ({ store }) => ({
    getBalancePayment: new Duck.Selector(({ getInvoicesList }) => state =>
      getInvoicesList(state)
        .filter(({ status }) => status !== 'rejected')
        .reduce((prev, { amount }) => prev + Number(amount), 0)
    ),
    getInvoiceIdSelected: state => state[store].invoiceIdSelected,
    getInvoicesList     : state => state[store].invoices
  }),
  types: [
    'FETCH_INVOICES',
    'CREATE_INVOICE',
    'CANCEL_INVOICE',
    'SUCCESS_CANCEL_INVOICE',
    'SUCCESS_CREATE_INVOICE',
    'SELECT_INVOICE',
    'FETCH_INVOICE_PENDING'
  ]
})
