import React, { Component } from 'react'
import { LoadingContainer } from './style'

class CardLoading extends Component {
  // static whyDidYouRender = true

  render() {
    return (
      <LoadingContainer>
        <img
          alt='loading'
          src='https://cdn.krowdy.com/images/loader.gif'
          style={{ width: '150px'  }} />
      </LoadingContainer>

    )
  };
}

export default CardLoading
