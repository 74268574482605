
const jobs = (state = { loading: true }, action)=> {
  let newState
  switch (action.type) {
    case 'GET_JOBS_INTERVIEW_DATA': {
      newState = { ...state, loading: true }
      break
    }
    case 'JOBS_INTERVIEW': {
      const { listJob } = action
      let jobList = []
      if(listJob !== undefined)
        jobList =  listJob.map(job => {
          const jobTemp = {
            _id       : job._id,
            colorTeam : job.teamId.color,
            dateEnd   : job.expirationDate,
            dateStart : job.publishday,
            enterprise: job.companyId.name,
            namejob   : job.title,
            team      : job.teamId.name
          }

          return jobTemp
        })

      newState = { ...state, listJob: jobList,loading: false }
      break
    }
    case 'JOBS_TOTAL_INTERVIEW': {
      const { totalJob } = action

      newState =  { ...state,  totalJob }
      break
    }
    case 'CLEAR_MESSAGE': {
      newState = { ...state, message: null, success: null }
      break
    }
    case 'GET_JOBS_CANDIDATES_INTERVIEW_DATA': {
      newState = { ...state, loading: true }
      break
    }
    case 'JOBS_CANDIDATE_INTERVIEW': {
      const { listCandidate } = action
      let result = listCandidate.map(({ assigned,schedule, status, ...rest })=>{
        rest['schedules'] = schedule ? [ { old: true, schedule: schedule } ] : []

        return { ...rest, assigned, changeAssigned: false,edit: !assigned, loading: false, schedule, status }
      })

      newState = { ...state,  listCandidate: result,loading: false }
      break
    }
    case 'RECEIVED_JOB_INFO': {
      const { data: { job: { expirationDate }, path } } = action

      newState = { ...state, expirationDate, path   }
      break
    }
    case 'UPDATE_INTERVIEW_INIT': {
      const { listCandidate } = state
      const { candidateTaskId } = action

      for (let task of listCandidate)
        if(task.candidateTaskId === candidateTaskId)
          task['loading'] = true

      newState =  { ...state, listCandidate: JSON.parse(JSON.stringify(listCandidate)) }
      break
    }
    case 'UPDATE_INTERVIEW_FINISHED': {
      const { listCandidate } = state
      const { candidateTaskId, success, message } = action

      for (let task of listCandidate)
        if(task.candidateTaskId === candidateTaskId) {
          task['loading'] = false
          task['assigned'] = success
          task['edit'] = !success
        }
      const { data: { resultTaskId } } = action
      newState = { ...state,listCandidate: JSON.parse(JSON.stringify(listCandidate)), message, resultTaskId, success  }

      break
    }
    default: {
      newState = { ...state }
      break
    }
  }

  return newState
}

export default jobs

