import { call, put, takeEvery, select } from 'redux-saga/effects'
import { ErrorType, Post } from 'utils/Request'
import ducks from 'reducers/assigners/resolveNextPending'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks

const { types } = ducks

const { REACT_APP_RESTPONSIBLE } = process.env

function* rejectAndResolveNextPending() {
  try {
    yield put({ type: types.POST_PENDING })
    const Authorization = yield select(selectorsUser.getToken)
    const codeTasks = yield select(selectorsUser.getFilters)

    const { data: pendingTask } = yield call(Post, `${REACT_APP_RESTPONSIBLE}/assigners/resolveNextPending`, { codeTasks }, { Authorization })

    yield put({
      payload: {
        pendingTask
      },
      type: types.POST_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.POST, rejectAndResolveNextPending)
]
