import { call, put, takeEvery, select } from 'redux-saga/effects'
import { ErrorType, Post } from 'utils/Request'
import ducks from 'reducers/vouchers/valid'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks

const { types } = ducks

const { REACT_APP_RESTPONSIBLE } = process.env

function* validVoucher({ urlS3, input, activityKrowderIds }) {
  try {
    yield put({ type: types.POST_PENDING })
    const Authorization = yield select(selectorsUser.getToken)

    const { data: response, success, message } = yield call(
      Post, `${REACT_APP_RESTPONSIBLE}/voucher/validate`,
      { activityKrowderIds, input, urlS3 },
      { Authorization }
    )

    if(!success) throw new Error(message)

    yield put({
      payload: {
        error  : message,
        input  : urlS3 ? response?.voucherData : input,
        isValid: Boolean(response?.isValid)
      },
      type: types.POST_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.POST_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.POST_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.POST, validVoucher)
]

