import produce from 'immer'
import base from './base'
import { parseJobData } from './const'

export default base({
  initialState: {
    jobData: {
      company: {}
    },
    loadingJobInfo: false,
    successJobInfo: false
  },
  namespace: 'krowdy',
  store    : 'jobData'
}).extend({
  creators: ({ types }) => ({
    getJobInformation: (jobId) => ({ jobId, type: types.FETCH_INFO_JOB })
  }),
  reducer: (state, action, { types }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.LOADING_GET_JOB_INFO:
          draft.loadingJobInfo = true
          break
        case types.SUCCESS_GET_JOB_INFO:
          if(action.success)
            draft.jobData = parseJobData(action.data)

          draft.successJobInfo = action.success
          draft.loadingJobInfo = false
          break

        default:
          break
      }
    }),
  types: [
    'FETCH_INFO_JOB',
    'LOADING_GET_JOB_INFO',
    'SUCCESS_GET_JOB_INFO'
  ]
})
