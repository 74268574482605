import { call, put, takeEvery, select } from 'redux-saga/effects'
import { Get, ErrorType } from 'utils/Request'

import userDucks from 'reducers/user'

import Cookies from 'js-cookie'
import { keyLocalStorage } from 'utils/constantes'
import { keyBy } from 'utils/index'

const { types, selectors } = userDucks

const { REACT_APP_ACCOUNTS_BACKEND, REACT_APP_RESTPONSIBLE } = process.env

function* getAuth() {
  try {
    yield put({ type: types.FETCH_PENDING })

    const Authorization = yield select(selectors.getToken)

    const payload = yield call(Get, `${REACT_APP_ACCOUNTS_BACKEND}/api/authenticate`, {
      Authorization
    })

    yield put({
      payload,
      type: types.FETCH_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e

    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

function* verifyUser() {
  try {
    yield put({ type: types.FETCH_PENDING })

    const Authorization = yield select(selectors.getToken)
    // console.time()
    const { data = {} } = yield call(Get, `${REACT_APP_RESTPONSIBLE}/users/verify`, {
      Authorization
    })

    const shortFilters = JSON.parse(localStorage.getItem(keyLocalStorage.Filter))
    const shortFilterKeys = keyBy(shortFilters ?? [], 'nameApp')

    yield put({
      payload: {
        userData: {
          ...data,
          filter: !data?.filter?.length ?
            [] :
            data.filter.map(item => ({ ...item, status: shortFilterKeys[item.nameApp]?.status ?? true }))
        }
      },
      type: types.FETCH_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

function* logout() {
  try {
    yield put({ type: types.FETCH_PENDING })

    const { accessToken, refreshToken } = yield select(selectors.getCookies)

    const buildUrlUri = data => {
      const keys = Object.keys(data)

      return keys.map(key => `${key}=${data[key]}`).join('&')
    }

    const { data } = yield call(Get, `${REACT_APP_ACCOUNTS_BACKEND}/api/logout?${buildUrlUri({ accessToken, refreshToken })}`, {
      Authorization: `Bearer ${accessToken}`
    })

    Cookies.remove('accessToken', { path: '' })
    Cookies.remove('refreshToken', { path: '' })
    Cookies.remove('iduser', { path: '' })
    window.location.reload()

    yield put({
      payload: {
        Cookies: data
      },
      type: types.FETCH_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.FETCH, getAuth),
  takeEvery(types.FETCH_DATA_USER, verifyUser),
  takeEvery(types.POST_LOGOUT, logout)
]
