import produce from 'immer'
import Duck from 'extensible-duck'

import base from './base'
import { BlockedTasks, TaskBlocked } from './const'
import {
  dateRangeBalanceSemanal,
  dateRangeBalanceMensual,
  structureBalance,
  TitleName,
  ButtonCall
} from 'utils/constantes'

export default base({
  initialState: {
    assignedFilters: {
      Estado: [
        {
          checked: false,
          key    : 'pending',
          name   : 'Por realizar'
        },
        {
          checked: false,
          key    : 'doing' || 'executed',
          name   : 'En proceso'
        },
        {
          checked: false,
          key    : 'completed',
          name   : 'Por facturar'
        },
        {
          checked: false,
          key    : 'invoiced',
          name   : 'Facturada'
        }
      ],
      Fecha: [ {
        key  : 'start',
        value: ''
      }, {
        key  : 'end',
        value: ''
      } ],
      Tarea: [
        {
          checked: false,
          key    : 'video-questionnaire',
          name   : 'Video cuestionario'
        },
        {
          checked: false,
          key    : 'video-interview',
          name   : 'Video entrevista'
        },
        {
          checked: false,
          key    : 'ranking',
          name   : 'Ranking'
        },
        {
          checked: false,
          key    : 'hunting',
          name   : 'Hunting'
        }
      ]
    },
    assignedTasks: [],
    balance      : [],
    certificate  : {},
    certification: {
      contentTask: {
        content: {
          instructions: []
        }
      },
      jobTitle: '',
      type    : ''
    },
    certificationCurrentTask: {
      activityKrowderId: '',
      isCertified      : false,
      resultTaskId     : ''
    },
    countLogs           : 0,
    currentCertification: {},
    currentTask         : {},
    datesFilter         : {
      balance: { dateRange: 1 },
      history: { dateRange: 1 }
    },
    error            : false,
    filter           : [],
    finishedTasks    : [],
    finishedTasksInfo: {
      page              : 1,
      perPage           : 25,
      rowsPerPageOptions: [ 25, 50, 100, 300, 500 ],
      totalPages        : 1
    },
    loading             : false,
    loadingBalance      : false,
    loadingCancel       : false,
    loadingCertified    : false,
    loadingFinish       : false,
    loadingLogs         : false,
    loadingOmmitTask    : false,
    loadingTasksAssigned: false,
    loadingTasksFinished: false,
    loadingTasksPending : false,
    logs                : [],
    messageCancel       : '',
    notify              : {
      message: '',
      type   : 'info'
    },
    searching           : false,
    showModalSeguimiento: false,
    status              : false,
    success             : false,
    successCancel       : false,
    successCertified    : false,
    summatory           : { month: 0, tasks: [], week: 0 },
    tasks               : [],
    totalTasks          : 0
  },
  namespace: 'krowdy',
  store    : 'tasks'
}).extend({
  creators: ({ types }) => ({
    addAssignedTasks           : (tasks) => ({ tasks, type: types.ADD_ASSIGNED_TASKS }),
    addLogs                    : (tasks) => ({ tasks, type: types.ADD_LOGS_TASKS }),
    addTasks                   : (tasks, userId, filter) => ({ filter, tasks, type: types.ADD_TASKS, userId }),
    assignedTasksKrowder       : (task) => ({ task, type: types.POST_ASSIGNED }),
    cancelTask                 : (activityKrowderId) => ({ activityKrowderId, type: types.CANCEL_TASK }),
    changeCheckedFinished      : (activityKrowderId) => ({ activityKrowderId, type: types.CHANGE_CHECKED_FINISHED }),
    changeRangeAssignedFilter  : (key, value) => ({ key, type: types.CHANGE_RANGE_ASSIGNED_FILTER, value }),
    changeSelectAssignedFilter : (key, filterName) => ({ filterName, key, type: types.CHANGE_SELECT_ASSIGNED_FILTER }),
    cleanAssigns               : () => ({ type: types.CLEAN_ASSIGNED_DATA }),
    closeCancelNotify          : () => ({ type: types.CLOSE_NOTIFY_CANCEL }),
    closeNotification          : () => ({ type: types.CLOSE_NOTIFY }),
    eliminateTaskFromList      : (activityKrowderId) => ({ activityKrowderId, type: types.ELIMINATE_TASK_FROM_LIST }),
    finishTask                 : (activityKrowderId) => ({ activityKrowderId, type: types.POST_FINISH_TASK }),
    getAssigned                : () => ({ type: types.FETCH_DATA_ASSIGNEDS }),
    getBalance                 : (taskDates, dateRange) => ({ dateRange, taskDates, type: types.FETCH_DATA_BALANCE }),
    getCertification           : (resultTaskId) => ({ resultTaskId, type: types.FETCH_DATA_CERTIFICATION }),
    getCountLogs               : (dates) => ({ dates, type: types.FETCH_DATA_COUNTS_LOGS }),
    getFinished                : (page, limit) => ({ limit, page, type: types.FETCH_DATA_FINISHEDS }),
    getInterviews              : () => ({ type: types.FETCH }),
    getLogs                    : (dates) => ({ dates, type: types.FETCH_DATA_LOGS }),
    getPendings                : (filter, searching) => ({ filter, searching, type: types.FETCH_DATA_PENDINGS }),
    getVideoUrlCertification   : (taskId) => ({ taskId, type: types.FETCH_URL_VIDEO_CERTIFICATION }),
    refreshAvailableTask       : (filter) => ({ filter, type: types.FILTER_AVAILABLE_DATA_TO_TASK }),
    rejectTasks                : (activityKrowderId) => ({ activityKrowderId, type: types.REJECT_TASK }),
    removeAssignedTasks        : (tasks) => ({ tasks, type: types.REMOVE_ASSIGNED_TASKS }),
    removeTask                 : (activityKrowderId) => ({ activityKrowderId, type: types.REMOVE_TASK }),
    removeTasks                : (tasks) => ({ tasks, type: types.REMOVE_TASKS }),
    selectAllItemsInTasks      : (params) => ({ ...params, type: types.SELECT_ALL_ITEMS_IN_TASKS }),
    selectItemInTasks          : (params) => ({ ...params, type: types.SELECT_ITEM_IN_TASKS }),
    setCurrentOpenedTask       : (task) => ({ task, type: types.SET_CURRENT_OPENED_TASK }),
    setCurrentTask             : (currentTask) => ({ currentTask, type: types.SET_CURRENT_TASK }),
    taskCertification          : (params) => ({ params, type: types.CERTIFICATION_CURRENT_TASK }),
    tasksOpened                : () => ({ type: types.TASK_OPENED }),
    updateCertification        : (activityKrowderId) => ({ activityKrowderId, type: types.UPDATE_DATA_CERTIFICATION }),
    updateChangePageFinished   : payload => ({ payload, type: types.UPDATE_PAGINATION_FINISHED }),
    updateChangePerPageFinished: payload => ({ payload, type: types.UPDATE_PAGINATION_FINISHED }),
    updateDateFilter           : (filter) => ({ filter, type: types.UPDATE_DATE_FILTER_TASKS }),
    updateFilterHistory        : (data) => ({ data, type: types.UPDATE }),
    updateFilterUser           : (data) => ({ data, type: types.UPDATE_TYPE_FILTER_TASKS }),
    updateFinishToAFK          : (dataToAFKTask) => ({ dataToAFKTask, type: types.UPDATE_FINISH_AFTER_AFK }),
    updateFinishedCall         : (activityKrowderId) => ({ activityKrowderId, type: types.UPDATE_FINISHED_CALL_PER_TASK }),
    updateSearchStatus         : (status) => ({ status, type: types.UPDATE_SEARCH_STATUS }),
    updateStatusTask           : (activityKrowderId) => ({ activityKrowderId, type: types.UPDATE_STATUS_TASK }),
    updateTasks                : (tasks, userId, filter) => ({ filter, tasks, type: types.UPDATE_TASKS, userId }),
    updateTriesAssigned        : (data) => ({ data, type: types.UPDATE_TRIES_ASSIGNED }),
    updateVisualTaskAFK        : (activityKrowderId) => ({ activityKrowderId, type: types.UPDATE_FINISH_TASK }),
    verificationTimeTasks      : (data) => ({ data, type: types.FILTER_TIME_TASKS }),
    verificationTimeblocked    : () => ({ type: types.FILTER_TASKS_BLOCKED }),
    verifyException            : (activityKrowderId) => ({ activityKrowderId, type: types.VERIFY_EXCEPTION }),
    verifyReposeForTask        : (activityKrowderId) => ({ activityKrowderId, type: types.VERIFY_REPOSE_FOR_ACTIVITY }),
    verifyReposeTaskLocal      : (data) => ({ data, type: types.POST_UPDATE_DATA_FOR_ACTIVITY_LOCAL })
  }),
  reducer: (state, action, { types }) =>
    produce(state, draft => {
      switch (action.type) {
        case types.UPDATE_PAGINATION_FINISHED:
          for (let key in action.payload) draft.finishedTasksInfo[key] = action.payload[key]

          draft.status = 'UPDATE_PAGINATION_FINISHED'
          break
        case types.POST_UPDATE_STATUS_TASK:
          for (let task of draft.assignedTasks)
            if(task._id === action.data._id) {
              task.status = action.data.status
              break
            }
          break
        case types.CHANGE_RANGE_ASSIGNED_FILTER:
          for (let date of draft.assignedFilters.Fecha)
            if(date.key === action.key) {
              date.value = action.value
              break
            }
          break

        case types.CHANGE_SELECT_ASSIGNED_FILTER:
          for (let filter of draft.assignedFilters[action.filterName])
            if(filter.key === action.key) {
              filter.checked = !filter.checked
              break
            }
          break

        case types.CHANGE_CHECKED_FINISHED:
          for (let task of draft.finishedTasks)
            if(task._id === action.activityKrowderId) {
              task.checked = !task.checked
              break
            }
          break

        case types.CHANGE_STATUS_FINISHED_TASKS:
          let index = action.activityKrowderIds.length

          for (let task of draft.finishedTasks) {
            for (let _id of action.activityKrowderIds)
              if(task._id === _id) {
                task.statusPayment = action.statusPayment
                task.checked = false
                if(task.invoiceIds) task.invoiceIds.push(action.invoiceId)
                else task.invoiceIds = [ action.invoiceId ]
                index--
                break
              }

            if(!index) break
          }

          break

        case types.FETCH_LOADING_DATA_PENDING:
          draft.loadingTasksPending = action.searching
          break

        case types.FETCH_LOADING_DATA_ASSIGNED:
          draft.loadingTasksAssigned = true
          break

        case types.FETCH_DATA_PENDING:

          const [ currentTaskFech ] = action.tasks

          // AddTasksAndUpdate(state.tasksCurrent,draft.tasksCurrent,action.tasks, action.userId,draft.tasks,action.filter)
          draft.tasks = action.tasks
          draft.currentTask = currentTaskFech
          draft.totalTasks = action.total
          draft.searching = action.searching
          draft.loadingTasksPending = false

          break
        case types.FETCH_DATA_ASSIGNED:
          draft.status = 'READY'

          draft.assignedTasks = action.tasks

          // BlockedTasks(state.assignedTasks, draft.assignedTasks, action.tasks)
          draft.loadingTasksAssigned =  false

          break

        case types.FETCH_DATA_FINISHED:
          draft.status = 'READY'

          draft.finishedTasks = action.tasks.map(task => ({ ...task, selected: false }))
          for (let key in action.pagination) draft.finishedTasksInfo[key] = action.pagination[key]
          draft.loadingTasksFinished = false

          break

        case types.UPDATE:
          const { data } = action

          draft.filter.type = data

          draft.status = 'UPDATED_FILTER'

          break
        case types.FILTER_TASKS_BLOCKED: // no se usa
          BlockedTasks(state.assignedTasks, draft.assignedTasks, state.assignedTasks)

          break
          /*  case types.FILTER_TIME_TASKS:
          const { user:{ userId },filter } = action.data

          // let filterByType1 = []
          // if(state.tasksCurrent.length > 0)
          //   filterByType1 = state.tasksCurrent.filter(t => filter.find(f => f.nameApp === t.codeTask && f.status))

          AddTasksAndUpdate(state.tasksCurrent,draft.tasksCurrent,state.tasksCurrent, userId,draft.tasks,filter)

          break */
          /*         case types.ADD_TASKS:

          AddTasksAndUpdate(state.tasksCurrent, draft.tasksCurrent, action.tasks, action.userId,draft.tasks,action.filter)

          draft.status = 'ADDED_TASKS'

          break
        case types.UPDATE_TASKS: */
          /*
          AddTasksAndUpdate(state.tasksCurrent, draft.tasksCurrent, action.tasks, action.userId, draft.tasks,action.filter)

          draft.status = 'UPDATE_TASKS'

          break
        case types.REMOVE_TASKS:
          action.tasks.forEach(({ activityKrowderId }) => {
            if(draft.tasks.findIndex((t) => t.activityKrowderId === activityKrowderId) !== -1)
              draft.tasks.splice(draft.tasks.findIndex((t) => t.activityKrowderId === activityKrowderId), 1)
            if(draft.tasksCurrent.findIndex((t) => t.activityKrowderId === activityKrowderId) !== -1)
              draft.tasksCurrent.splice(draft.tasksCurrent.findIndex((t) => t.activityKrowderId === activityKrowderId), 1)
          })

          draft.status = 'REMOVED_TASKS'

          break */
        case types.ADD_ASSIGNED_TASKS: // @deprecated

          for (const task of  action.tasks)
          {
            let newTask = task
            newTask.bloqueo = TaskBlocked(task)

            draft.assignedTasks.push(newTask)
          }

          draft.assignedTasks.sort((a, b) => {
            if(a.codeTask === 'video-interview') {
              if(a.status.includes('executed'))
                a.taskDate = null
              if(b.status.includes('executed'))
                b.taskDate = null

              return new Date(a.taskDate) - new Date(b.taskDate) | (new Date(a.taskDate) >= new Date(b.taskDate))
            }

            return false
          })

          draft.status = 'ADDED_ASSIGNED_TASKS'

          break
          /*  case types.UPDATE_TYPE_FILTER_TASKS:
          // const filterByType = state.tasksCurrent.filter(t => action.data.filter.find(f => f.nameApp === t.codeTask && f.status))

          // const filterByReject =  filterTaskReject(filterByType,filterByType,action.data.userId)
          draft.tasks = []

          AddTasksAndUpdate(state.tasksCurrent,draft.tasksCurrent,state.tasksCurrent, action.data.userId,draft.tasks,action.data.filter)

          draft.status = 'UPDATE_FILTER_TYPE_TASKS'

          break */
        case types.UPDATE_DATE_FILTER_TASKS:

          const { origin, finishDate, startDate, dateRange } = action.filter

          draft.datesFilter[origin] =  { dateRange, finishDate, startDate }

          break
        case types.SEND_UPDATE_CERTIFICATION:
          draft.loadingCertified = true
          break
        case types.UPDATE_CERTIFICATION:
          draft.currentTask.certified = action.payload.success
          draft.loadingCertified = false
          draft.successCertified = action.payload.success

          break
        case types.FAILED_CONFIRM_REQUEST:
          draft.loadingCertified = false
          break
        case types.ADD_LOGS_TASKS: // no se usa
          if(state.countLogs === 0)
            draft.logs = []

          action.tasks.forEach(task => {
            const {
              balance:{ dateRange:BdateRange, startDate:BstartDate, finishDate:BfinishDate },
              history:{ startDate:HstartDate, finishDate:HfinishDate }
            } = state.datesFilter
            const { finishedAt = new Date() } = task

            if(new Date(HstartDate) <  new Date(finishedAt) &&  new Date(finishedAt) <  new Date(HfinishDate)) {
              draft.logs.unshift(task)
              draft.countLogs += 1
              draft.logs = draft.logs.sort((a, b) => new Date(b.finishedAt) - new Date(a.finishedAt))
            }

            if(new Date(BstartDate) <  new Date(finishedAt) &&  new Date(finishedAt) <  new Date(BfinishDate)) {
              const { codeTask, price, finishedAt = new Date() } = task

              draft.balance = structureBalance(codeTask, BdateRange, state.balance, finishedAt, price)
              draft.summatory.week = parseFloat(draft.summatory.week) + parseFloat(task.price)
            }

            if(new Date(BstartDate).getMonth() ===  new Date(finishedAt).getMonth())
              draft.summatory.month = parseFloat(draft.summatory.month) + parseFloat(task.price)
          })

          draft.status = 'ADDED_LOGS_TASKS'

          break

        case types.SEND_FETCH_DATA_LOGS:

          draft.loadingLogs = true

          break
        case types.SEND_FETCH_BALANCE:
          draft.balance = state.datesFilter.balance.dateRange === 1 ? dateRangeBalanceSemanal : dateRangeBalanceMensual
          draft.loadingBalance = true

          break
        case types.SUCCESS_FETCH_BALANCE:
          draft.balance =  action.balance
          draft.summatory =  action.summatory
          draft.loadingBalance = false

          break
        case types.SUCCESS_DATA_LOGS:
          if(action.skip === 1)
            draft.logs = []

          action.logs.forEach(task => {
            if(!draft.logs.find(log => log.activityKrowderId === task.activityKrowderId))
              draft.logs.push(task)
          })

          draft.logs = draft.logs.sort((a, b) => new Date(b.finishedAt) - new Date(a.finishedAt))

          draft.loadingLogs = false

          draft.status = 'ADDED_LOGS_TASKS'

          break
        case types.UPDATE_SEARCH_STATUS:
          draft.searching = action.status

          break
        case types.POST_VERIFY_EXCEPTION:
          draft.status = 'VERIFYING_EXCEPTION'

          draft.loading = true

          draft.notify.type = 'info'
          draft.notify.message = 'Verificando tarea...'

          break
        case types.EXCEPTION_VERIFIED:
          const { data: { error, message }, activityKrowderId } = action.payload

          if(error && draft.assignedTasks.findIndex((t) => t.activityKrowderId === activityKrowderId) !== -1)
            draft.assignedTasks.splice(draft.assignedTasks.findIndex((t) => t.activityKrowderId === activityKrowderId), 1)

          draft.error = error === undefined ? false : error
          draft.success = !error
          draft.activityKrowderId = activityKrowderId
          draft.status = 'EXCEPTION_VERIFIED'

          draft.loading = false

          draft.notify.type = error ? 'error' : 'success'
          draft.notify.message = message

          break
        case types.CLOSE_NOTIFY:
          draft.loading = false
          draft.error = false
          draft.success = false
          draft.notify.message = ''
          draft.status = 'READY'

          break
        case types.TASK_OPENED:
          draft.status = 'READY'

          break
        case types.TASK_ASSIGNED:
          draft.status = 'TASK_ASSIGNED_SUCCESSFULLY'

          const { tasksList } = action.payload

          if(action.payload.success) {
            let newTask = action.payload.data
            draft.assignedTasks.push({ ...newTask, bloqueo: TaskBlocked(newTask, tasksList) })
          }

          if(action.id) draft.id = action.id

          for (let key in action.payload)
            draft[key] = action.payload[key]

          break
        case types.POST_UPDATE_DATA_FOR_ACTIVITY:
          state.assignedTasks.forEach(task => {
            if(task.activityKrowderId === action.activityKrowderId && task.codeTask === TitleName.TRACKING) {
              task.calls.repose = action.data.repose
              task.calls.reposeDate = action.data.reposeDate
            }
          })

          break
        case types.POST_UPDATE_DATA_FOR_ACTIVITY_LOCAL:

          state.assignedTasks.forEach(task => {
            if(task.activityKrowderId === action.data.activityKrowderId)
            {
              task.calls.tries = action.data.tries
              task.calls.repose =  action.data.repose
              task.calls.reposeDate = action.data.reposeDate
            }
          })

          break
        case types.UPDATE_FINISHED_CALL_PER_TASK:
          draft.assignedTasks.forEach(task => {
            if(task.activityKrowderId === action.activityKrowderId)
              task.calls.finished = true
          })

          break
        case types.POST_UPDATE_TASKS_AFTER_AFK:
          draft.assignedTasks.forEach(task => {
            if(task.activityKrowderId === action.data.activityKrowderId) {
              task.calls.finished = action.data.finished
              task.calls.typeFinished = action.data.typeFinished
            }
          })

          break
        case types.UPDATE_FINISH_TASK:
          draft.assignedTasks.forEach(task => {
            if(task.activityKrowderId === action.activityKrowderId) {
              task.calls.finished = true
              task.calls.typeFinished = ButtonCall.KROWDER_AFK.typeFinished
            }
          })

          break
        case types.ELIMINATE_TASK_FROM_LIST:

          draft.assignedTasks.splice(draft.assignedTasks.findIndex((t) =>
            t.activityKrowderId === action.activityKrowderId
          ), 1)

          break
        case types.CLEAN_ASSIGNED_DATA:
          draft.assignedTasks = []
          draft.logs = []
          // console.log('draft.assignedTasks_________________', draft.assignedTasks)

          break

        case types.UPDATE_TRIES_ASSIGNED:

          draft.assignedTasks.forEach((task, index) => {
            if(task.activityKrowderId === action.data.activityKrowderId)
              draft.assignedTasks[index].calls.tries = action.data.tries
          })

          break
        case types.POST_REMOVE_TASK_PENDING:
        case types.POST_CANCEL_TASK_PENDING:
          draft.loadingCancel = true
          break
        case types.POST_TASK_TASK_PENDING:
          draft.loadingFinish = true
          break
        case types.POST_REMOVE_TASK_FULFILLED:
        case types.POST_CANCEL_TASK_FULFILLED:
          if(action.success) {
            const indexFound = draft.assignedTasks.findIndex(({ _id }) => _id === action.activityKrowderId)
            if(indexFound !== -1)
              draft.assignedTasks.splice(indexFound, 1)
          }
          draft.successCancel = action.success
          draft.messageCancel = action.message
          draft.loadingCancel = false
          break
        case types.POST_FINISH_TASK_FULFILLED:
          if(action.success) {
            const indexFound = draft.assignedTasks.findIndex(({ _id }) => _id === action.activityKrowderId)
            if(indexFound !== -1)
              draft.assignedTasks.splice(indexFound, 1)
          }
          draft.loadingFinish = false
          break
        case types.CLOSE_NOTIFY_CANCEL:
          draft.loadingCancel = false
          draft.successCancel = false
          draft.messageCancle = ''
          break
        case types.CERTIFICATION_CURRENT_TASK:
          draft.certificationCurrentTask = action.params
          break
        case types.SUCCESS_VIDEO_URL_CERTIFICATION:
          draft.currentCertification = action.data
          break
        case types.REJECT_TASK_PENDING:
          draft.loadingOmmitTask = true
          break

        case types.REJECT_TASK_FULFILLED:
          draft.loadingOmmitTask = false
          if(action.payload.success) {
            const [ curentTaskReject ] = action.payload.data.tasks
            draft.tasks = action.payload.data.tasks
            draft.currentTask = curentTaskReject
            draft.totalTasks = action.payload.data.total
          }
          break
        case types.SELECT_ITEM_IN_TASKS:
          const indexFound = draft.finishedTasks.findIndex(({ _id }) => _id === action.checked)
          if(indexFound !== -1) {
            draft.finishedTasks[indexFound].disabled = false
            draft.finishedTasks[indexFound].selected = !draft.finishedTasks[indexFound].selected
          }
          break
        case types.SELECT_ALL_ITEMS_IN_TASKS:
          draft.finishedTasks.forEach((elem) => {
            elem.selected = action.checked
          })

          break

        case types.FETCH_INVOICE_FULLFIELD:
          draft.status = 'READY'

          draft.finishedTasks = action.tasks
          draft.loadingTasksFinished = false
          break

        case types.SET_CURRENT_TASK:
          draft.currentTask = action.currentTask

          break
        default:
          break
      }
    }),
  selectors: ({ store }) => ({
    getAmountFinishedTasks: new Duck.Selector(({ getFinishedTasks }) => state =>
      getFinishedTasks(state).reduce((amount, { price = 0, selected }) => selected ? amount + price : amount, 0)
    ),
    getAssignedFilters      : state => state[store].assignedFilters,
    getAssignedTasks        : state => state[store].assignedTasks,
    getCertificationData    : state => state[store].certificationCurrentTask,
    getCheckedsFinishedTasks: new Duck.Selector(({ getFinishedTasks }) => state =>
      getFinishedTasks(state).map(({ checked }) => checked)
    ),
    getFinishedTasks  : state => state[store].finishedTasks,
    getIdFinishedTasks: new Duck.Selector(({ getFinishedTasks }) => state =>
      getFinishedTasks(state).filter(({ _id, selected }) => _id && selected).map(({ _id }) => _id)
    ),
    getIsSelectedInvoiced: new Duck.Selector(({ getFinishedTasks }) => state =>
      // se debe usar solo statusPayment para el "invoiced"
      getFinishedTasks(state).filter(({ selected, statusPayment }) => selected && statusPayment !== 'pending').length > 0
    ),
    getLoadingCancel          : state => state[store].loadingCancel,
    getPaginationFinishedTasks: state => {
      const { finishedTasksInfo: { page, perPage } } = state[store]

      return { page, perPage }
    },
    getStatus            : state => state[store].status,
    getTasksError        : state => state[store].error,
    getToPayFinishedTasks: new Duck.Selector(({ getFinishedTasks }) => state =>
      getFinishedTasks(state).filter(({ selected }) => selected).reduce((prev, { codeTask, price, nameTask, urlIcon }) => {
        if(!prev[codeTask])
          prev[codeTask]  = { amount: price, count: 1, description: nameTask, icon: urlIcon }
        else
          prev[codeTask]  = {
            amount     : prev[codeTask].amount + price,
            count      : ++prev[codeTask].count,
            description: prev[codeTask].description,
            icon       : prev[codeTask].icon
          }

        return prev
      }, {})
    )
  }),
  types: [
    'FETCH_LOADING_DATA_ASSIGNED',
    'FETCH_LOADING_DATA_FINISHED',
    'FETCH_LOADING_DATA_PENDING',

    'FETCH_DATA_PENDINGS',
    'FETCH_DATA_PENDING',
    'FETCH_DATA_ASSIGNED',
    'FETCH_DATA_FINISHED',
    'FETCH_DATA_ASSIGNEDS',
    'FETCH_DATA_FINISHEDS',
    'FETCH_DATA_CERTIFICATION',
    'FETCH_DATA_HISTORY',
    'FETCH_DATA_BALANCE',
    'FETCH_DATA_LOGS',
    'FETCH_DATA_ADD_HUNTING',
    'FETCH_DATA_COUNTS_LOGS',

    'CHANGE_STATUS_FINISHED_TASKS',

    'ADD_TASKS',
    'ADD_ASSIGNED_TASKS',
    'ADD_LOGS_TASKS',

    'UPDATE_STATUS_TASK',
    'POST_UPDATE_STATUS_TASK',

    'UPDATE_TASKS',
    'UPDATE_DATA_CERTIFICATION',
    'UPDATE_DATE_FILTER_TASKS',
    'UPDATE_CERTIFICATION',
    'UPDATE_SEARCH_STATUS',

    'POST_ASSIGNED',
    'POST_CERTIFICATION',
    'POST_VERIFY_EXCEPTION',

    'REJECT_TASK',
    'REJECT_TASK_PENDING',
    'REJECT_TASK_FULFILLED',
    'SELECT_ITEM_IN_TASKS',
    'SEND_FETCH_BALANCE',
    'SEND_UPDATE_CERTIFICATION',
    'SEND_FETCH_DATA_LOGS',

    'CANCEL_TASK',
    'SUCCESS_FETCH_BALANCE',

    'SUCCESS_DATA_LOGS',
    'FILTER_TASKS_BLOCKED',
    'VERIFY_EXCEPTION',
    'EXCEPTION_VERIFIED',

    'CLOSE_NOTIFY',
    'TASK_OPENED',
    'TASK_ASSIGNED',

    'VERIFY_REPOSE_FOR_ACTIVITY',
    'POST_UPDATE_DATA_FOR_ACTIVITY',
    'POST_UPDATE_DATA_FOR_ACTIVITY_LOCAL',

    'UPDATE_FINISHED_CALL_PER_TASK',
    'POST_UPDATE_TASKS_AFTER_AFK',
    'UPDATE_FINISH_AFTER_AFK',
    'UPDATE_FINISH_TASK',
    'ELIMINATE_TASK_FROM_LIST',

    'UPDATE_AVAILABLE_TASKS',
    'FILTER_AVAILABLE_DATA_TO_TASK',
    'CLEAN_ASSIGNED_DATA',
    'UPDATE_TRIES_ASSIGNED',

    'SEND_FETCH_ADD_HUNTING',
    'POST_CANCEL_TASK_FULFILLED',
    'POST_CANCEL_TASK_PENDING',
    'CLOSE_NOTIFY_CANCEL',
    'FAILED_CONFIRM_REQUEST',
    'CERTIFICATION_CURRENT_TASK',

    'FETCH_URL_VIDEO_CERTIFICATION',
    'LOADING_VIDEO_URL_CERTIFICATION',
    'SUCCESS_VIDEO_URL_CERTIFICATION',
    'SET_CURRENT_OPENED_TASK',
    'CHANGE_RANGE_ASSIGNED_FILTER',
    'CHANGE_SELECT_ASSIGNED_FILTER',
    'CHANGE_CHECKED_FINISHED',
    'SELECT_ALL_ITEMS_IN_TASKS',

    'FETCH_INVOICE_FULLFIELD',
    'SET_CURRENT_TASK',
    'UPDATE_PAGINATION_FINISHED',

    'POST_FINISH_TASK',
    'POST_FINISH_TASK_FULFILLED',
    'POST_FINISH_TASK_PENDING',

    'REMOVE_TASK',
    'POST_REMOVE_TASK_FULFILLED',
    'POST_REMOVE_TASK_PENDING'
  ]
})
