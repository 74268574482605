import { call, put, takeEvery, select } from 'redux-saga/effects'
import { Get, ErrorType } from 'utils/Request'
import { keyBy } from 'utils'
import taskConfigurationsDucks from 'reducers/taskConfigurations'
import userDucks from 'reducers/user'

const { selectors: selectorsUser } = userDucks

const { types } = taskConfigurationsDucks

const {
  REACT_APP_RESTPONSIBLE
} = process.env

function* fetchTaskConfigurations() {
  try {
    yield put({ type: types.FETCH_PENDING })

    const Authorization = yield select(selectorsUser.getToken)

    const { data }  = yield call(
      Get,
      `${REACT_APP_RESTPONSIBLE}/taskconfigurations/`,
      { Authorization },
    )

    const items = (data ?? []).map((item) => ({
      ...item,
      formatDate: 'MMM. dd, yyyy - hh:mm TT',
      image     : item.urlIcon,
      nameTask  : item.codeTask,
      title     : item.name
    }))

    yield put({
      payload: {
        itemByCodeTask: keyBy(items, 'codeTask'),
        items
      },
      type: types.FETCH_FULFILLED
    })
  } catch (e) {
    const { type, message, response: { data: { message: messageResponse } = {} } = {} } = e
    switch (type) {
      case ErrorType.Cancel:
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          error: messageResponse || message,
          type : types.FETCH_FAILURE
        })
        break
    }
  }
}

export default [
  takeEvery(types.FETCH, fetchTaskConfigurations)
]
