import React, { Component } from 'react'
import CardLoading from '../loadingCard'

class Loading extends Component {
  render() {
    return (
      <div>
        <CardLoading />
      </div>
    )
  }
}

export default Loading
