import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action'

import base from '../base'
import produce from 'immer'

export default base({
  initialState: {
    input  : null,
    isValid: null,
    urlS3  : null
  },
  namespace: 'krowdy',
  store    : 'vouchers/valid'
}).extend({
  creators: ({ types }) => ({
    reset: () => ({
      type: types.RESET
    }),
    validVoucher: ({ urlS3, input, activityKrowderIds }) => ({
      [ERROR_ACTION]   : types.POST_FAILURE,
      [WAIT_FOR_ACTION]: types.POST_FULFILLED,
      activityKrowderIds,
      input,
      type             : types.POST,
      urlS3
    })
  }),
  reducer: (state, action, { types, initialState }) =>
    produce(state, () => {
      switch (action.type) {
        case types.RESET: {
          return initialState
        }
        default:
          break
      }
    }),
  selectors: ({ store }) => ({
    getVoucherIsValid   : state => state[store].isValid,
    getVoucherValidation: state => state[store]
  }),
  types: [
    'RESET'
  ]
})
